const regex = {
  email: new RegExp(
    '^(([^<>()\\[\\]\\\\.,;:\\s@"]+(\\.[^<>()\\[\\]\\\\.,;:\\s@"]+)*)|(".+"))@((\\[[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\])|(([a-zA-Z\\-0-9]+\\.)+[a-zA-Z]{2,}))$'
  ),
  mobile: new RegExp(
    /^\+?\d{7,16}$/
    // /^(\s*|(\+\d{1,3}(-| )?\(?\d\)?(-| )?\d{1,3})|(\(?\d{2,3}\)?))(-| )?(\d{3,4})(-| )?(\d{4})(( x| ext)\d{1,5}){0,1}$/
  ),
  url: new RegExp("^http(s?):\\/\\/\\S+(\\/\\S+)*(\\/)?$"),
  price: new RegExp(/^\d+(\.\d{1,2})?$/),
  number: new RegExp("^[0-9]+$"),

  decimalNumber: new RegExp("^[+-]?([0-9]*[.])?[0-9]+$"),
  amountRefunded: new RegExp("/^d{10}$/"),
  referenceNumber: new RegExp("^[a-zA-Z0-9]+$"),
  GST: new RegExp(
    "/^([0][1-9]|[1-2][0-9]|[3][0-5])([a-zA-Z]{5}[0-9]{4}[a-zA-Z]{1}[1-9a-zA-Z]{1}[zZ]{1}[0-9a-zA-Z]{1})+$/"
  ),
  Time: new RegExp("^([0-9]|0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$"),
  pinCode: new RegExp("^(\\d{6})$"),
  strongPassword: new RegExp(
    "/^(?=.*\\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?!.*\\s).{8,15}$/"
  ),
  passport: new RegExp("^[A-Z][0-9]{8}$")
};

export class Validators {
  static GST(value, message) {
    if (value) {
      const result = regex.GST.test(value);
      if (!result) return { error: true, message };
    }
  }

  static time(value, message) {
    if (value) {
      const result = regex.Time.test(value);
      if (!result) return { error: true, message };
    }
  }

  static pinCode(value, message) {
    if (value) {
      const result = regex.pinCode.test(value);
      if (!result) return { error: true, message };
    }
  }

  static passport(value, message) {
    if (value) {
      const result = regex.passport.test(value);
      if (!result) return { error: true, message };
    }
  }

  static max100(value, message) {
    if (parseFloat(value) > 100) {
      return { error: true, message };
    }
  }

  static email(value, message) {
    if (value) {
      const result = regex.email.test(value);
      if (!result) return { error: true, message };
    }
    // return false;
  }

  static mobile(value, message) {
    if (value) {
      const result = regex.mobile.test(value);
      if (!result) return { error: true, message };
    }
  }

  static required(value, message) {
    if (!value || !value.toString().trim().length || value === "") {
      return { error: true, message };
    }
    return false;
  }

  static number(value, message) {
    const length = value ? value.toString().length : 0;

    if (length > 0) {
      const result = regex.number.test(value);
      if (!result) {
        return { error: true, message };
      }
    }

    return false;
  }
  static decimalNumber(value, message) {
    var regexp = /^(\d+(\.\d{0,4})?|\.?\d{1,2})$/;
    const length = value ? value.toString().length : 0;

    if (length > 0) {
      const result = regexp.test(value);
      if (!result) {
        return { error: true, message };
      }
    }

    return false;
  }

  static decimal(value, message) {
    const length = value ? value.toString().length : 0;
    let floatValues = /^[0-9]*[.]?[0-9]+/;
    if (length > 0) {
      const result = floatValues.test(value);
      if (!result) {
        return { error: true, message };
      }
    }

    return false;
  }

  static noRoom(value, message) {
    const length = value ? value.toString().length : 0;
    if (length > 4) {
      const result = regex.amountRefunded.test(value);
      if (!result) {
        return { error: true, message };
      }
    }

    return false;
  }

  static price(value, message) {
    const length = value ? value.toString().length : 0;

    if (length > 0 || value <= 0) {
      const result = regex.price.test(value);
      if (!result) {
        return { error: true, message };
      }
    }

    return false;
  }

  static digitValidation(value, message) {
    const length = value ? value.toString().length : 0;
    if (length > 3) {
      return { error: true, message };
    }

    return false;
  }

  static password(value, message) {
    let regexValue = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?!.*\s).{8,15}$/;
    const result = regexValue.test(value);
    if (!result) {
      return { error: true, message };
    }
    return false;
  }

  static amountRefunded(value, message) {
    const length = value ? value.toString().length : 0;

    if (length > 10) {
      const result = regex.amountRefunded.test(value);
      if (!result) {
        return { error: true, message };
      }
    }

    return false;
  }

  static referenceNumber(value, message) {
    const length = value ? value.toString().length : 0;

    if (length > 100) {
      const result = regex.referenceNumber.test(value);
      if (!result) {
        return { error: true, message };
      }
    }

    return false;
  }

  static charLimit20(value, message, num) {
    const length = value ? value.toString().length : 0;
    if (length > num) {
      return { error: true, message };
    }
    return false;
  }

  static minCharLimit(value, message, num) {
    const length = value.trim() ? value.toString().length : 0;
    if (length !== 0 && length < num) {
      return { error: true, message };
    }
    return false;
  }
}

export const validateInput = (validators, value) => {
  if (validators && validators.length) {
    for (let i = 0; i < validators.length; i++) {
      const error = validators[i]?.check(
        value,
        validators[i].message,
        validators[i].num
      );
      if (error) {
        return error;
      }
    }
  }
  return false;
};

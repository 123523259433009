import { URLS } from "../../library/common/constants/UrlConstants";

export const errorHandler = error => {
  if (error.response) {
    const loginURL = URLS.login;
    const translation = URLS.getLanguage("en_IN");
    if (error.response.status === 401) {
      if (
        error.response.config.url !== loginURL &&
        error.response.config.url !== translation
      ) {
        sessionStorage.clear();
        window.location.reload();
      }
    }
  }
};

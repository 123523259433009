import React, { Component } from "react";
import LeftSideImage from "../../../../modules/Login/LeftSideImage";
import strings from "../Translation/Translate";
import "../../../../modules/Login/loginStyles.scss";
import { Link } from "react-router-dom";

export default class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError() {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch() {
    // You can also log the error to an error reporting service
    // logErrorToMyService(error, errorInfo);
    // console.log(error, errorInfo);
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return (
        <div className="vh-100 login d-lg-flex justify-content-center align-items-center">
          <LeftSideImage />
          <div className="col-sm-12 col-lg-6 login-form vh-100 d-lg-flex justify-content-center align-items-center">
            <div className="row justify-content-center">
              <div className="col-lg-12 col-sm-12 text-center">
                <h3 className="pb-2"> {strings.something_went_wrong}</h3>
                <Link to="/login">Go to home</Link>
              </div>
            </div>
          </div>
        </div>
      );
    }

    return this.props.children;
  }
}

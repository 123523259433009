import React from "react";

export default function Section({ label }) {
  return (
    <div className="section">
      {label}
      <hr className="m-0 line" />
    </div>
  );
}

import { storeConstants } from "../../library/common/constants";

const initialState = {
  FeedbackList: [],
  userFilterCondition: {
    page: 1,
    filter: [],
    sort: [],
    graphql: null,
    size: 10,
    filterValue: {},
  },
};

const FeedbackReducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case storeConstants.FETCH_FEEDBACKLIST:
      return {
        ...state,
        FeedbackList: action.payload,
      };
    case storeConstants.PAGE_NUMBER_USER:
      return {
        ...state,
        userFilterCondition: action.payload,
      };
    default:
      return state;
  }
};

export default FeedbackReducer;

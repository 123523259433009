import React from "react";
import memoize from "memoize-one";
import { strings } from "../../../library/common/components";

const TempOrderListJson = (permission) =>
  memoize((handleAction) => [
    {
      name: strings.temp_id,
      sortable: true,
      sort: "id",
      selector: (row) => row?.id,
    },
    {
      name: strings.temp_phoneNumber,
      sortable: true,
      sort: "phoneNumber",
      selector: (row) => row?.phoneNumber,
    },
    {
      name: strings.temp_legalId,
      sortable: true,
      sort: "legalId",
      selector: (row) => row?.legalId,
    },
    {
      name: strings.temp_userId,
      sortable: true,
      sort: "userId",
      selector: (row) => row?.userId,
    },
    {
      name: strings.temp_email,
      sortable: true,
      sort: "email",
      selector: (row) => row?.email,
    },
    {
      name: strings.temp_loc,
      sortable: true,
      sort: "loc",
      selector: (row) => row?.loc,
    },
    {
      name: strings.temp_wholesalerCode,
      sortable: true,
      sort: "wholesalerCode",
      selector: (row) => row?.wholesalerCode,
    },
    {
      name: strings.temp_brandIdentifier,
      sortable: true,
      sort: "brandIdentifier",
      selector: (row) => row?.brandIdentifier,
    },
    {
      name: strings.temp_skuIdentifier,
      sortable: true,
      sort: "skuIdentifier",
      selector: (row) => row?.skuIdentifier,
    },
    {
      name: strings.temp_quantity,
      sortable: true,
      sort: "quantity",
      selector: (row) => row?.quantity,
    },
    {
      name: strings.temp_orderDate,
      sortable: true,
      sort: "orderDate",
      selector: (row) => row?.orderDateString,
    },
  ]);

export default TempOrderListJson;

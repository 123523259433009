import React from "react";
import { downloadExportFile, fetchFromStorage } from "../../../utilities";
import { identifiers } from "../../../../library/common/constants/IdentifierConstants";
import axios from "axios";
import Config from "../../../../core/Config";

const DownloadCsv = ({ apiUrl, title, fileName, filters, type = "xlsx" }) => {

  const getTemplate = async () => {
    const token = fetchFromStorage(identifiers.token);
    axios({
      url: Config.BaseURL + apiUrl,
      method: "post",
      headers: { Authorization: `Bearer ${token.access_token}` },
      responseType: "blob",
      data: {
        filters: filters(),
      },
    })
      .then((response) => {
        downloadExportFile(response, type, fileName);
      })
      .catch((err) => {
      });
  };

  return (
    <button
      type="submit"
      data-toggle="tooltip"
      title={title}
      className="cursor-pointer ml-sm-2 btn-primary btn br-3"
      onClick={getTemplate}
    >
      <i className="fa fa-download" />
    </button>
  );
};
export default DownloadCsv;

import { storeConstants } from "../constants/index";
const windowWidth = window.innerWidth < 992;
const initialState = {
  isLoggedIn: false,
  token: null,
  theme: {},
  translation: {},
  isToggle: windowWidth,
  roleList: [],
  userGroupList: [],
  roleListPage: {
    page: 1,
    filter: [],
    sort: [],
    graphql: null,
    size: 10,
    filterValue: {}
  },

  username: null,
  notificationCount: 0
};

const AuthReducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case storeConstants.SET_AUTHENTICATION:
      return {
        ...state,
        token: action.payload,
        isLoggedIn: true
      };
    case storeConstants.THEME:
      return {
        ...state,
        theme: action.payload
      };

    case storeConstants.GET_LANGUAGE:
      return {
        ...state,
        translation: action.payload
      };
    case storeConstants.SIDEBAR_TOGGLE:
      return {
        ...state,
        isToggle: action.payload
      };
    case storeConstants.ROLE_LIST:
      return {
        ...state,
        roleList: action.payload
      };
    case storeConstants.PAGE_NUMBER_ROLE_LIST:
      return {
        ...state,
        roleListPage: action.payload
      };
      
    case storeConstants.USERNAME:
      return {
        ...state,
        username: action.payload
      };
    case storeConstants.NOTIFICATION_COUNT:
      return {
        ...state,
        notificationCount: action.payload
      };
    case storeConstants.LOGOUT:
      return initialState;
    default:
      return state;
  }
};

export default AuthReducer;

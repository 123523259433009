import { storeConstants } from '../constants/index';

const initialState = {
  passportIssuePlaceFilter: {
    page: 1,
    filter: [],
    sort: [],
    graphql: null,
    size: 10,
    filterValue: {},
  },
};

const FilterReducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case storeConstants.PASSPORT_ISSUE_PLACE:
      return {
        ...state,
        passportIssuePlaceFilter: action.payload,
      };
    default:
      return state;
  }
};

export default FilterReducer;

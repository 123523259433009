export const en_IN = {
  login_welcome_text: "Login",
  login_text: "Login",
  email_text: "Email Id",
  search_filter_text: "Search Filter",
  clear_filter_text: "Clear filter",
  password_text: "Password",
  login_error: "Please enter valid email id and password",
  invalid_email_error: "Email is invalid",
  generic_error: "The application has encountered an unknown error.",
  common_ok_title: "OK",
  no_data_found: "No record found",
  loginFormError: "Please check email or password",
  yesText: 'Yes',
  noText: 'No',

  common_required_message: "Fields marked with * are mandatory",
  common_button_close: "Cancel",
  common_button_delete: "Delete",
  common_button_edit: "Edit",
  common_button_back: "Back",
  common_button_next: "Next",
  common_button_add: "Add",
  common_button_save: "Save",
  common_button_submit: "Submit",
  common_button_continue: "Save & Next",
  common_button_saveAndClose: "Save & Close",
  common_button_order_book: "Order Book",
  common_button_order_completed: "Training Completed",
  common_button_prev: "Previous",
  common_button_update: "Update",
  common_save_message: "Data saved successfully.",
  common_update_message: "Data updated successfully.",
  common_copy_message: "Data copy successfully.",
  common_delete_message: "Data deleted successfully.",
  common_delete_confirm_message: "Are you sure! You want to delete?",
  common_delete_modal_header_text: "Delete",
  common_button_verified: "Verified",
  common_button_non_verified: "Not-verified",

  something_went_wrong: "Something went wrong. Please try again.",
  captchaValidation: "Captcha is required",
  emailValidation: "Email is mandatory",
  invalidEmailValidation: "Email is invalid",
  endDateValidation:
    "End date should be greater than current date & start date",
  sourcingEndDateError: "Date should be greater than current date",
  visaDateUpdateError: "Expiry date should be greater than issue date",
  dataTable_row_per_page_text: "Rows per page",
  char_limit_20_error: "The entered value exceeds the maximum length.",
  char_min_limit: "Please enter minimum length",

  requiredText: "This field is mandatory",
  requiredNumber: "Invalid number",
  required_error_prefix_message: "Please fill details for",
  required_valid_mobile: "Please enter valid mobile no.",

  changePassword_text_confirmPassword: "Confirm Password",
  changePassword_text_newPassword: "New Password",
  changePassword_text_oldPassword: "Old Password",
  changePassword_title_text: "Confirm Password",
  changePassword_text_changePassword: "Change Password",
  changePassword_success_msg: "Password changed successfully",
  password_missMatch_msg: "password mismatch",

  dashboard_title_text: "Dashboard",

  menu_text_roles: "Roles",
  menu_text_dashboard: "Lead Management",
  menu_text_revenue: "Revenue",
  menu_text_upload: "Upload",
  roles_column_action: "Action",
  roles_button_add: "New Role",
  roles_column_delete: "Delete",
  roles_column_description: "Description",
  roles_column_edit: "Edit",
  roles_column_name: "Names",
  roles_column_privilege: "Privilege",
  roles_column_privilegeName: "Privilege Name",
  roles_text_name: "Role Name",
  roles_title_add: "Add Role",
  roles_title_edit: "Edit Role",
  roles_title_privilege: "Privileges",
  roles_title_roles: "Roles",

  privilege_title_text: "Privilege",
  privilege_select_all_text: "Select All",
  all_mandatory_msg: "All * fields are mandatory",
  formName_mandatory_msg: "Form Name and at least one component is mandatory",

  menu_text_users: "System Users",
  users_list_title: "System Users",
  users_column_action: "Action",
  users_column_email: "Email ID",
  users_column_name: "Name",
  users_column_phone: "Phone No.",
  users_column_location: "Location",
  users_column_employee_number: "User No.",
  users_column_department: "Department",
  users_column_designation: "Designation",
  users_column_manager: "Manager",
  users_column_role: "Role",
  users_text_designation: "Designation",
  users_text_email: "Email",
  users_text_first_name: "First Name",
  users_text_employee_number: "User No.",
  users_text_last_name: "Last Name",
  users_text_phone: "Phone No.",
  users_text_role: "Roles",
  users_text_user_group: "User Group",
  users_title_add: "Add User",
  users_title_edit: "Edit User",
  users_button_add: "New User",
  users_button_reset_password: "Reset password",
  users_button_active_user: "Active",
  users_button_in_active_user: "In-active",
  users_text_loc: "LOC",
  users_text_location: "Location",
  users_text_manager: "Manager",
  userDeleteMessage:
    "This user already assigned as a manager to some user. Are you sure you want to delete?",

  userprofile_success_message: "Profile details updated successfully.",
  userprofile_button_changePassword: "Change Password",
  userprofile_button_updateProfile: "Update Profile",
  updateProfile_title_text: "User Profile",

  required_password_msg:
    "Password length should be between 8 and 15 characters and must contain at least one lowercase letter, one uppercase letter, one numeric digit and one special character",

  file_upload_error: "Please upload valid file type or file size",

  forgot_password_title: "Forgot Password",
  reset_password_title: "Reset Password",
  reset_password_codePlaceholder: "Enter code",
  reset_password_resend_code: "Resend Code",
  reset_password_resend_code_successMsg: "Verification code sent successfully",
  reset_password_successMessage: "Password Changed Successfully",

  menu_text_PharmacyUsers: "GSKAssist Users",
  PharmacyUsers_list_title: "GSKAssist Users",
  PharmacyUsers_column_action: "Action",
  PharmacyUsers_column_email: "Email",
  PharmacyUsers_column_last_name: "Last Name",
  PharmacyUsers_column_first_name: "First Name",
  PharmacyUsers_column_number: "Phone Number",
  PharmacyUsers_text_email: "Email",
  PharmacyUsers_text_first_name: "First Name",
  PharmacyUsers_text_last_name: "Last Name",
  PharmacyUsers_text_number: "Phone Number",
  PharmacyUsers_title_add: "Add Pharmacy Users",
  PharmacyUsers_title_edit: "Edit Pharmacy Users",
  PharmacyUsers_button_add: "New User",
  PharmacyUsers_button_customer_type: "User Type",
  PharmacyUsers_button_active_user: "Active",
  PharmacyUsers_column_loc_name: "Loc Name",
  PharmacyUsers_column_email_consent: "Email Consent",
  PharmacyUsers_column_waba_consent: "Waba Consent",
  PharmacyUsers_column_CustomerType: "Customer Type",
  PharmacyUsers_column_BusinessNameRegister: "Business Name (Register)",
  PharmacyUsers_column_NumberOfPharmaciesRegister: "Number of Pharmacies (Register)",


  PharmacyUsers_button_in_active_user: "In-active",
  PharmacyUsers_userId: "User Id",

  menu_text_ClientPosAccount: "Customer Mapping",
  ClientPosAccount_list_title: "Customer Mapping",
  ClientPosAccount_column_action: "Action",
  ClientPosAccount_column_loc: "LOC",
  ClientPosAccount_column_period: "Period",
  ClientPosAccount_column_client_code: "Client Code",
  ClientPosAccount_column_ct_code: "Client Teritory Code",
  ClientPosAccount_column_pt_code: "PoS Teritory Code",
  ClientPosAccount_column_pos_code: "PoS Code",
  ClientPosAccount_column_wholesaler_code: "Wholesaler Code",
  ClientPosAccount_column_account_code: "Account Code",
  ClientPosAccount_column_legalID_code: "Legal ID Code",
  ClientPosAccount_column_user_phone_number: "User Id",
  ClientPosAccount_column_representative_phone_number:
    "Representative User Id",

  ClientPosAccount_column_zip_code: "Zip Code",
  ClientPosAccount_column_city: "City",
  ClientPosAccount_column_street: "Street",
  ClientPosAccount_column_pharmacy_name: "Pharmacy Name",



  ClientPosAccount_text_loc: "LOC",
  ClientPosAccount_text_period: "Period",
  ClientPosAccount_text_client_code: "Client Code",
  ClientPosAccount_text_ct_code: "Client Teritory Code",
  ClientPosAccount_text_pt_code: "PoS Teritory Code",
  ClientPosAccount_text_pos_code: "PoS Code",
  ClientPosAccount_text_wholesaler_code: "Wholesaler Code",
  ClientPosAccount_text_account_code: "Account Code",
  ClientPosAccount_text_legalID_code: "Legal ID Code",
  ClientPosAccount_text_user_phone_number: "User Id",
  ClientPosAccount_text_representative_phone_number: "Representative User Id",

  ClientPosAccount_text_zip_code: "Zip Code",
  ClientPosAccount_text_city: "City",
  ClientPosAccount_text_street: "Street",
  ClientPosAccount_text_pharmacy_name: "Pharmacy Name",


  ClientPosAccount_title_add: "Add Client Pos Account",
  ClientPosAccount_title_edit: "Edit Client Pos Account",
  ClientPosAccount_button_add: "New User",
  ClientPosAccount_button_active_user: "Active",
  ClientPosAccount_button_in_active_user: "In-active",

  menu_text_Segmentation: "Customer Segmentation",
  Segmentation_list_title: "Customer Segmentation",
  Segmentation_column_action: "Action",
  Segmentation_column_loc: "LOC",
  Segmentation_column_period: "Period",
  Segmentation_column_client_code: "Pos/Client Code",
  Segmentation_column_brand_hash: "Brand #",
  Segmentation_column_brand: "Brand",
  Segmentation_column_ean: "EAN",
  Segmentation_column_sku_hash: "SKU#",
  Segmentation_column_sku: "SKU",
  Segmentation_column_segment: "Segment",
  Segmentation_text_loc: "LOC",
  Segmentation_text_period: "Period",
  Segmentation_text_client_code: "Pos/Client Code",
  Segmentation_text_brand_hash: "Brand #",
  Segmentation_text_brand: "Brand",
  Segmentation_text_ean: "EAN",
  Segmentation_text_sku_hash: "SKU#",
  Segmentation_text_sku: "SKU",
  Segmentation_text_segment: "Segment",
  Segmentation_title_add: "Add Segmentation",
  Segmentation_title_edit: "Edit Segmentation",
  Segmentation_button_add: "New User",
  Segmentation_button_active_user: "Active",
  Segmentation_button_in_active_user: "In-active",

  menu_text_PredefinedOffer: "Pre-defined Offers",
  PredefinedOffer_list_title: "Pre-defined Offers",
  PredefinedOffer_column_action: "Action",
  PredefinedOffer_column_loc: "LOC",
  PredefinedOffer_column_period: "Period",
  PredefinedOffer_column_client_code: "Pos/Client Code",
  PredefinedOffer_column_brand_hash: "Brand #",
  PredefinedOffer_column_brand: "Brand",
  PredefinedOffer_column_ean: "EAN",
  PredefinedOffer_column_sku_hash: "SKU#",
  PredefinedOffer_column_sku: "SKU",
  PredefinedOffer_column_range1: "Range 1",
  PredefinedOffer_column_range2: "Range 2",
  PredefinedOffer_column_range3: "Range 3",
  PredefinedOffer_column_range4: "Range 4",
  PredefinedOffer_column_range5: "Range 5",
  PredefinedOffer_column_max: "Max",
  PredefinedOffer_column_no_discount: "No Discount",
  PredefinedOffer_column_range0_discount: "Range 0 Discount",
  PredefinedOffer_column_range1_discount: "Range 1 Discount",
  PredefinedOffer_column_range2_discount: "Range 2 Discount",
  PredefinedOffer_column_range3_discount: "Range 3 Discount",
  PredefinedOffer_column_range4_discount: "Range 4 Discount",
  PredefinedOffer_column_range5_discount: "Range 5 Discount",
  PredefinedOffer_column_range0_pay_term: "Range 0 Pay Terms",
  PredefinedOffer_column_range1_pay_term: "Range 1 Pay Terms",
  PredefinedOffer_column_range2_pay_term: "Range 2 Pay Terms",
  PredefinedOffer_column_range3_pay_term: "Range 3 Pay Terms",
  PredefinedOffer_column_range4_pay_term: "Range 4 Pay Terms",
  PredefinedOffer_column_range5_pay_term: "Range 5 Pay Terms",
  PreDefinedOffer_column_rangeZeroPriceReference: 'Range 0 Price Reference',
  PreDefinedOffer_column_rangeOnePriceReference: 'Range 1 Price Reference',
  PreDefinedOffer_column_rangeTwoPriceReference: 'Range 2 Price Reference',
  PreDefinedOffer_column_rangeThreePriceReference: 'Range 3 Price Reference',
  PreDefinedOffer_column_rangeFourPriceReference: 'Range 4 Price Reference',
  PreDefinedOffer_column_rangeFivePriceReference: 'Range 5 Price Reference',
  PredefinedOffer_text_loc: "LOC",
  PredefinedOffer_text_period: "Period",
  PredefinedOffer_text_client_code: "Pos/Client Code",
  PredefinedOffer_text_brand_hash: "Brand #",
  PredefinedOffer_text_brand: "Brand",
  PredefinedOffer_text_ean: "EAN",
  PredefinedOffer_text_sku_hash: "SKU#",
  PredefinedOffer_text_sku: "SKU",
  PredefinedOffer_text_segment: "Segment",
  PredefinedOffer_title_add: "Add Pre-defined Offer",
  PredefinedOffer_title_edit: "Edit Pre-defined Offer",
  PredefinedOffer_button_add: "New User",
  PredefinedOffer_button_active_user: "Active",
  PredefinedOffer_button_in_active_user: "In-active",

  menu_text_StandardSegmentOffer: "Standard Segment",
  StandardSegmentOffer_list_title: "Standard Segment",
  StandardSegmentOffer_column_action: "Action",
  StandardSegmentOffer_column_loc: "LOC",
  StandardSegmentOffer_column_period: "Period",
  StandardSegmentOffer_column_segment: "Segment",
  StandardSegmentOffer_column_brand_hash: "Brand #",
  StandardSegmentOffer_column_brand: "Brand",
  StandardSegmentOffer_column_ean: "EAN",
  StandardSegmentOffer_column_sku_hash: "SKU#",
  StandardSegmentOffer_column_sku: "SKU",
  StandardSegmentOffer_column_range1: "Range 1",
  StandardSegmentOffer_column_range2: "Range 2",
  StandardSegmentOffer_column_range3: "Range 3",
  StandardSegmentOffer_column_range4: "Range 4",
  StandardSegmentOffer_column_range5: "Range 5",
  StandardSegmentOffer_column_max: "Max",
  StandardSegmentOffer_column_no_discount: "No Discount",
  StandardSegmentOffer_column_range0_discount: "Range 0 Discount",
  StandardSegmentOffer_column_range1_discount: "Range 1 Discount",
  StandardSegmentOffer_column_range2_discount: "Range 2 Discount",
  StandardSegmentOffer_column_range3_discount: "Range 3 Discount",
  StandardSegmentOffer_column_range4_discount: "Range 4 Discount",
  StandardSegmentOffer_column_range5_discount: "Range 5 Discount",
  StandardSegmentOffer_column_range0_pay_term: "Range 0 Pay Terms",
  StandardSegmentOffer_column_range1_pay_term: "Range 1 Pay Terms",
  StandardSegmentOffer_column_range2_pay_term: "Range 2 Pay Terms",
  StandardSegmentOffer_column_range3_pay_term: "Range 3 Pay Terms",
  StandardSegmentOffer_column_range4_pay_term: "Range 4 Pay Terms",
  StandardSegmentOffer_column_range5_pay_term: "Range 5 Pay Terms",
  StandardSegmentOffer_column_rangeZeroPriceReference: 'Range 0 Price Reference',
  StandardSegmentOffer_column_rangeOnePriceReference: 'Range 1 Price Reference',
  StandardSegmentOffer_column_rangeTwoPriceReference: 'Range 2 Price Reference',
  StandardSegmentOffer_column_rangeThreePriceReference: 'Range 3 Price Reference',
  StandardSegmentOffer_column_rangeFourPriceReference: 'Range 4 Price Reference',
  StandardSegmentOffer_column_rangeFivePriceReference: 'Range 5 Price Reference',
  StandardSegmentOffer_text_loc: "LOC",
  StandardSegmentOffer_text_period: "Period",
  StandardSegmentOffer_text_brand_hash: "Brand #",
  StandardSegmentOffer_text_brand: "Brand",
  StandardSegmentOffer_text_ean: "EAN",
  StandardSegmentOffer_text_sku_hash: "SKU#",
  StandardSegmentOffer_text_sku: "SKU",
  StandardSegmentOffer_text_segment: "Segment",
  StandardSegmentOffer_title_add: "Add Standard Segment Offer",
  StandardSegmentOffer_title_edit: "Edit Standard Segment Offer",
  StandardSegmentOffer_button_add: "New User",
  StandardSegmentOffer_button_active_user: "Active",
  StandardSegmentOffer_button_in_active_user: "In-active",

  menu_text_ValidOffer: "Valid Offers",
  ValidOffer_list_title: "Valid Offers",
  ValidOffer_column_action: "Action",
  ValidOffer_column_loc: "LOC",
  ValidOffer_column_period: "Period",
  ValidOffer_column_segment: "Segment",
  ValidOffer_column_client_code: "Pos/Client Code",
  ValidOffer_column_brand_hash: "Brand #",
  ValidOffer_column_brand: "Brand",
  ValidOffer_column_ean: "EAN",
  ValidOffer_column_sku_hash: "SKU#",
  ValidOffer_column_sku: "SKU",
  ValidOffer_column_range1: "Range 1",
  ValidOffer_column_range2: "Range 2",
  ValidOffer_column_range3: "Range 3",
  ValidOffer_column_range4: "Range 4",
  ValidOffer_column_range5: "Range 5",
  ValidOffer_column_max: "Max",
  ValidOffer_column_no_discount: "No Discount",
  ValidOffer_column_range0_discount: "Range 0 Discount",
  ValidOffer_column_range1_discount: "Range 1 Discount",
  ValidOffer_column_range2_discount: "Range 2 Discount",
  ValidOffer_column_range3_discount: "Range 3 Discount",
  ValidOffer_column_range4_discount: "Range 4 Discount",
  ValidOffer_column_range5_discount: "Range 5 Discount",
  ValidOffer_column_range0_pay_term: "Range 0 Pay Terms",
  ValidOffer_column_range1_pay_term: "Range 1 Pay Terms",
  ValidOffer_column_range2_pay_term: "Range 2 Pay Terms",
  ValidOffer_column_range3_pay_term: "Range 3 Pay Terms",
  ValidOffer_column_range4_pay_term: "Range 4 Pay Terms",
  ValidOffer_column_range5_pay_term: "Range 5 Pay Terms",
  ValidOffer_column_rangeZeroPriceReference: 'Range 0 Price Reference',
  ValidOffer_column_rangeOnePriceReference: 'Range 1 Price Reference',
  ValidOffer_column_rangeTwoPriceReference: 'Range 2 Price Reference',
  ValidOffer_column_rangeThreePriceReference: 'Range 3 Price Reference',
  ValidOffer_column_rangeFourPriceReference: 'Range 4 Price Reference',
  ValidOffer_column_rangeFivePriceReference: 'Range 5 Price Reference',
  ValidOffer_text_loc: "LOC",
  ValidOffer_text_period: "Period",
  ValidOffer_text_segment: "Segment",
  ValidOffer_text_client_code: "Pos/Client Code",
  ValidOffer_text_brand_hash: "Brand #",
  ValidOffer_text_brand: "Brand",
  ValidOffer_text_ean: "EAN",
  ValidOffer_text_sku_hash: "SKU#",
  ValidOffer_text_sku: "SKU",
  ValidOffer_title_add: "Add Valid Offer",
  ValidOffer_title_edit: "Edit Valid Offer",
  ValidOffer_button_add: "New User",
  ValidOffer_button_active_user: "Active",
  ValidOffer_button_in_active_user: "In-active",

  menu_text_CommercialCondition: "Product Definitions",
  CommercialCondition_list_title: "Product Definitions",
  CommercialCondition_column_action: "Action",
  CommercialCondition_column_loc: "LOC",
  CommercialCondition_column_period: "Period",
  CommercialCondition_column_period_start: "Period Start",
  CommercialCondition_column_period_end: "Period End",
  CommercialCondition_column_brand_hash: "Brand #",
  CommercialCondition_column_brand: "Brand",
  CommercialCondition_column_brand_appear_order: "Brand Appear Order",
  CommercialCondition_column_ean: "EAN",
  CommercialCondition_column_sku_hash: "SKU#",
  CommercialCondition_column_sku: "SKU",
  CommercialCondition_column_sku_appear_order: "SKU Appear Order",
  CommercialCondition_column_brand_minQ: "Brand Min Q",
  CommercialCondition_column_brand_maxQ: "Brand Max Q",
  CommercialCondition_column_sku_minQ: "SKU Min Q",
  CommercialCondition_column_sku_maxQ: "SKU Max Q",
  CommercialCondition_column_offer_level: "Offer Level",
  CommercialCondition_column_offer_range_type: "Offer Range Type",
  CommercialCondition_column_disc_execution: "Disc Execution",
  CommercialCondition_column_brand_Oninvoice_disc_type: "On Invoice Disc Type",
  CommercialCondition_column_brand_Offinvoice_disc_type:
    "Off Invoice Disc Type",
  CommercialCondition_column_plan_commitment: "Plan Commitment",
  CommercialCondition_column_allow_commercial_insights: "Allow Commercial Insights",
  CommercialCondition_column_daily_offer: "Daily Offer",
  CommercialCondition_column_allow_plan_upgrade: "Allow Plan Upgrade",
  CommercialCondition_column_allow_range0: "Allow Range 0",
  CommercialCondition_column_allow_bonus: "Allow Bonus",
  CommercialCondition_column_allow_checkout_value: "Allow Checkout Total Value",
  CommercialCondition_column_allow_brand_reminder: "Allow Brand Reminder",
  CommercialCondition_column_allow_sku_reminder: "Allow SKU Reminder",
  CommercialCondition_column_close_abs_vol: "Close Abs Vol",
  CommercialCondition_column_close_vol: "Close % Vol",
  CommercialCondition_text_loc: "LOC",
  CommercialCondition_text_period: "Period",
  CommercialCondition_text_brand_identifier: "Brand #",
  CommercialCondition_text_brand: "Brand",
  CommercialCondition_text_ean: "EAN",
  CommercialCondition_text_sku_identifier: "SKU #",
  CommercialCondition_text_sku: "SKU",
  CommercialCondition_text_offer_level: "Offer Level",
  CommercialCondition_text_offer_range_type: "Offer Range Type",
  CommercialCondition_title_add: "Add Commercial Condition",
  CommercialCondition_title_edit: "Edit Commercial Condition",
  CommercialCondition_button_add: "New User",
  CommercialCondition_button_active_user: "Active",
  CommercialCondition_button_in_active_user: "In-active",
  CommercialCondition_button_skuPtw: "SKU PTW",
  CommercialCondition_button_skuPtc: "SKU PTC",
  CommercialCondition_button_skuPtp: "SKU PTP",
  CommercialCondition_button_showReferencePrice: "Show Reference Price",

  EventConfiguration_list_title: 'Event Configuration',
  event_config_loc: 'LOC',
  event_config_language_code: 'Language Code',
  event_config_event: 'Event',
  event_config_content_type: 'Content Type',
  event_config_email: 'Email',
  event_config_email_frequency: 'Email Frequency',
  event_config_email_frequency_period: 'Email Frequency Period',
  event_config_email_max_notification_count: 'Max Notification Count',
  event_config_email_template_name: 'Email Template Name',
  event_config_waba: 'Waba',
  event_config_waba_frequency: 'Waba Frequency',
  event_config_waba_frequency_period: 'Waba Frequency Period',
  event_config_waba_max_notification_count: 'Waba Max Notification Count',
  event_config_waba_template_name: 'Waba Template Name',
  event_config_news_board: 'News Board Notification',
  event_config_news_board_notification_display_count: 'News Board Notification Display Count',
  event_config_threshold_type: 'Threshold Type',
  event_config_threshold_value: 'Threshold Value',

  EventHistory_list_title: 'Event History',
  event_history_eventType: 'Event Type',
  event_history_createdAt: 'Created At',
  event_history_userId: 'User Id',
  event_history_loginDisplayCount: 'Login Display Count',
  event_history_message: 'Message',

  notificationText: 'Notification',

  menu_text_ManualOffers: 'Manual Offers',
  ManualOffer_list_title: "Manual Offers",
  ManualOffer_column_action: "Action",
  ManualOffer_column_loc: "LOC",
  ManualOffer_column_period: "Period",
  ManualOffer_column_client_code: "Pos/Client Code",
  ManualOffer_column_brand_hash: "Brand #",
  ManualOffer_column_brand: "Brand",
  ManualOffer_column_ean: "EAN",
  ManualOffer_column_sku_hash: "SKU#",
  ManualOffer_column_sku: "SKU",
  ManualOffer_column_range1: "Range 1",
  ManualOffer_column_range2: "Range 2",
  ManualOffer_column_range3: "Range 3",
  ManualOffer_column_range4: "Range 4",
  ManualOffer_column_range5: "Range 5",
  ManualOffer_column_max: "Max",
  ManualOffer_column_no_discount: "No Discount",
  ManualOffer_column_range0_discount: "Range 0 Discount",
  ManualOffer_column_range1_discount: "Range 1 Discount",
  ManualOffer_column_range2_discount: "Range 2 Discount",
  ManualOffer_column_range3_discount: "Range 3 Discount",
  ManualOffer_column_range4_discount: "Range 4 Discount",
  ManualOffer_column_range5_discount: "Range 5 Discount",
  ManualOffer_column_range0_pay_term: "Range 0 Pay Terms",
  ManualOffer_column_range1_pay_term: "Range 1 Pay Terms",
  ManualOffer_column_range2_pay_term: "Range 2 Pay Terms",
  ManualOffer_column_range3_pay_term: "Range 3 Pay Terms",
  ManualOffer_column_range4_pay_term: "Range 4 Pay Terms",
  ManualOffer_column_range5_pay_term: "Range 5 Pay Terms",
  ManualOffer_column_rangeZeroPriceReference: 'Range 0 Price Reference',
  ManualOffer_column_rangeOnePriceReference: 'Range 1 Price Reference',
  ManualOffer_column_rangeTwoPriceReference: 'Range 2 Price Reference',
  ManualOffer_column_rangeThreePriceReference: 'Range 3 Price Reference',
  ManualOffer_column_rangeFourPriceReference: 'Range 4 Price Reference',
  ManualOffer_column_rangeFivePriceReference: 'Range 5 Price Reference',
  ManualOffer_text_loc: "LOC",
  ManualOffer_text_period: "Period",
  ManualOffer_text_client_code: "Pos/Client Code",
  ManualOffer_text_brand_hash: "Brand #",
  ManualOffer_text_brand: "Brand",
  ManualOffer_text_ean: "EAN",
  ManualOffer_text_sku_hash: "SKU#",
  ManualOffer_text_sku: "SKU",
  ManualOffer_text_segment: "Segment",
  ManualOffer_title_add: "Add Pre-defined Offer",
  ManualOffer_title_edit: "Edit Pre-defined Offer",
  ManualOffer_button_add: "New User",
  ManualOffer_button_active_user: "Active",
  ManualOffer_button_in_active_user: "In-active",

  menu_text_BonusOffers: 'Bonus Offers',
  BonusOffer_list_title: "Bonus Offers",
  BonusOffer_column_action: "Action",
  BonusOffer_column_loc: "LOC",
  BonusOffer_column_period: "Period",
  BonusOffer_column_client_code: "Pos/Client Code",
  BonusOffer_column_brand_hash: "Brand #",
  BonusOffer_column_brand: "Brand",
  BonusOffer_column_ean: "EAN",
  BonusOffer_column_sku_hash: "SKU#",
  BonusOffer_column_sku: "SKU",
  BonusOffer_column_range1: "Range 1",
  BonusOffer_column_range2: "Range 2",
  BonusOffer_column_range3: "Range 3",
  BonusOffer_column_range4: "Range 4",
  BonusOffer_column_range5: "Range 5",
  BonusOffer_column_max: "Max",
  BonusOffer_column_no_discount: "No Discount",
  BonusOffer_column_range0_discount: "Range 0 Discount",
  BonusOffer_column_range1_discount: "Range 1 Discount",
  BonusOffer_column_range2_discount: "Range 2 Discount",
  BonusOffer_column_range3_discount: "Range 3 Discount",
  BonusOffer_column_range4_discount: "Range 4 Discount",
  BonusOffer_column_range5_discount: "Range 5 Discount",
  BonusOffer_column_range0_pay_term: "Range 0 Pay Terms",
  BonusOffer_column_range1_pay_term: "Range 1 Pay Terms",
  BonusOffer_column_range2_pay_term: "Range 2 Pay Terms",
  BonusOffer_column_range3_pay_term: "Range 3 Pay Terms",
  BonusOffer_column_range4_pay_term: "Range 4 Pay Terms",
  BonusOffer_column_range5_pay_term: "Range 5 Pay Terms",
  BonusOffer_column_rangeZeroPriceReference: 'Range 0 Price Reference',
  BonusOffer_column_rangeOnePriceReference: 'Range 1 Price Reference',
  BonusOffer_column_rangeTwoPriceReference: 'Range 2 Price Reference',
  BonusOffer_column_rangeThreePriceReference: 'Range 3 Price Reference',
  BonusOffer_column_rangeFourPriceReference: 'Range 4 Price Reference',
  BonusOffer_column_rangeFivePriceReference: 'Range 5 Price Reference',
  BonusOffer_text_loc: "LOC",
  BonusOffer_text_period: "Period",
  BonusOffer_text_client_code: "Pos/Client Code",
  BonusOffer_text_brand_hash: "Brand #",
  BonusOffer_text_brand: "Brand",
  BonusOffer_text_ean: "EAN",
  BonusOffer_text_sku_hash: "SKU#",
  BonusOffer_text_sku: "SKU",
  BonusOffer_text_segment: "Segment",
  BonusOffer_title_add: "Add Pre-defined Offer",
  BonusOffer_title_edit: "Edit Pre-defined Offer",
  BonusOffer_button_add: "New User",
  BonusOffer_button_active_user: "Active",
  BonusOffer_button_in_active_user: "In-active",


  menu_text_Wholesalers: "Wholesaler Definitions",
  Wholesalers_list_title: "Wholesaler Definitions",
  Wholesalers_column_action: "Action",
  Wholesalers_column_code: "Wholesaler Code",
  Wholesalers_column_loc: "LOC",
  Wholesalers_column_name_variation: "Name Variation",
  Wholesalers_column_wholesaler_account_status: "Account Status",
  Wholesalers_column_name: "Name",
  Wholesalers_text_name_variation: "Name Variation",
  Wholesalers_text_wholesaler_account_status: "Account Status",
  Wholesalers_text_code: "Wholesaler Code",
  Wholesalers_text_loc: "LOC",
  Wholesalers_text_name: "Name",
  Wholesalers_title_add: "Add Wholesalers",
  Wholesalers_title_edit: "Edit User",
  Wholesalers_button_add: "New User",
  Wholesalers_button_active_user: "Active",
  Wholesalers_button_in_active_user: "In-active",

  menu_text_OfferPeriod: "Period Definitions",
  OfferPeriod_list_title: "Period Definitions",
  OfferPeriod_column_action: "Action",
  OfferPeriod_column_period: "Period",
  OfferPeriod_column_loc: "LOC",
  OfferPeriod_column_period_start: "Start Date",
  OfferPeriod_column_period_end: "End Date",
  OfferPeriod_text_loc: "LOC",
  OfferPeriod_text_period: "Period",
  OfferPeriod_text_period_start: "Start Date",
  OfferPeriod_text_period_end: "End Date",
  OfferPeriod_title_add: "Add Offer Period",
  OfferPeriod_title_edit: "Edit Offer Period",
  OfferPeriod_button_add: "New User",
  OfferPeriod_button_active_user: "Active",
  OfferPeriod_button_in_active_user: "In-active",

  menu_text_Order: "Orders",
  Order_list_title: "Orders",
  Order_column_action: "Action",
  Order_column_loc: "LOC",
  Order_column_period: "Period",
  Order_column_order_id: "Order Id",
  Order_column_order_date: "Order Date",
  Order_column_order_type: "Order Type",
  Order_column_internal_order_status: "Internal Order Status",
  Order_column_order_internal_number: "Order Internal Number",
  Order_column_order_external_number: "Order External Number",
  Order_column_order_external_status_date: "Order External Status Date",
  Order_column_order_external_status: "Order External Status",
  Order_column_wholesaler_code: "Wholesaler Code",
  Order_column_account_code: "Account Code",
  Order_column_legal_id_code: "Legal Id Code",
  Order_column_brand_identifier: "Brand #",
  Order_column_brand: "Brand",
  Order_column_ean: "EAN",
  Order_column_sku_identifier: "SKU#",
  Order_column_sku: "SKU",
  Order_column_requested_volume: "Requested Volume",
  Order_column_created_by: "Order Created By",

  Order_column_invoiced_volume: "Invoice Volume",
  Order_column_on_invoice_disc: "On Invoice Disc",
  Order_column_on_invoice_free_goods: "On Invoice FreeGoods",
  Order_column_on_invoice_pay_terms: "On Invoice Pay Terms",
  Order_column_off_invoice_disc: "Off Invoice Disc",
  Order_column_off_invoice_free_goods: "Off Invoice FreeGoods",
  Order_column_offer_type: "Offer Type",
  Order_column_user_id: "User Id",
  Order_text_loc: "LOC",
  Order_text_discountValue: "Discount Value",
  Order_text_invoiceValue: "Invoice Value",
  Order_text_referencePrice: "Reference Price",
  Order_text_period: "Period",
  Order_text_internal_order_number: "Internal Order Number",
  Order_text_external_order_number: "External Order Number",
  Order_text_wholesaler_code: "Wholesaler Code",
  Order_text_brand_identifier: "Brand #",
  Order_text_brand: "Brand",
  Order_text_ean: "EAN",
  Order_text_sku_identifier: "SKU #",
  Order_text_sku: "SKU",
  Order_title_add: "Add Order",
  Order_title_edit: "Edit Order",
  Order_button_add: "New Order",
  Order_button_active_user: "Active",
  Order_button_in_active_user: "In-active",

  TempOrder_text_phone: "Phone Number",
  TempOrder_text_Date: "Order Date",
  TempOrder_text_LegalId: "LegaL Id",
  TempOrder_text_UserId: "User Id",
  TempOrder_text_Email: "Email",





  menu_text_Accounts: "Wholesaler Accounts",
  Accounts_list_title: "Wholesaler Accounts",
  Accounts_column_action: "Action",
  Accounts_column_code: "Wholesaler Code",
  Accounts_column_account_code: "Account Code",
  Accounts_column_legalId_code: "Legal Id Code",
  Accounts_column_zipCode: "Zip Code",
  Accounts_column_city: "City",
  Accounts_column_streetName: "Street",
  Accounts_column_pharmacyName: "Pharmacy Name",

  Accounts_column_user_phone_number: "User Phone Number",
  Accounts_column_user_id: "User Id",
  Accounts_column_image_url: "Image URL",
  Accounts_column_user_firstName: 'First Name',
  Accounts_column_user_lastName: 'Last Name',

  Accounts_column_db_account_status: "Database Account Status",
  Accounts_column_customerService_account_status:
    "Customer Service Account Status",
  Accounts_column_loc: "LOC",
  Accounts_text_code: "Wholesaler Code",
  Accounts_text_loc: "LOC",
  Accounts_text_account_code: "Account Code",
  Accounts_text_legalId_code: "Legal Id Code",
  Accounts_text_zip_code: "Zip Code",
  Accounts_text_city: "City",
  Accounts_text_street: "Street",
  Accounts_text_pharmacy_name: "Pharmacy Name",


  Accounts_text_wholesaler_code: "Wholesaler Code",
  Accounts_text_user_phone_number: "User Phone Number",
  Accounts_text_db_account_status: "Database Account Status",
  Accounts_text_customerService_account_status:
    "Customer Service Account Status",
  Accounts_title_add: "Add Accounts",
  Accounts_title_edit: "Edit User",
  Accounts_button_add: "New User",
  Accounts_button_active_user: "Active",
  Accounts_button_in_active_user: "In-active",

  external_wholesaler_mapping: 'External Wholesaler Mapping',
  external_wholesaler_mapping_wholesaler_Code: 'Wholesaler Code',
  external_wholesaler_mapping_wholesaler_Identifier: 'Wholesaler Identifier',
  external_wholesaler_mapping_pharmassist_Identifier: 'Pharmassist Identifier',

  menu_text_feedback: 'Feedback',
  Feedback_column_feedbackType: 'Feedback Type',
  Feedback_column_loc: "LOC",
  Feedback_column_date: 'Date',
  Feedback_column_userId: 'User Id',
  Feedback_column_userName: 'User Name',
  Feedback_column_question: 'Question',
  Feedback_column_response: 'Response',


  menu_text_LOC_Order: 'LOC Order',
  LOC_Order_Period: 'Period',
  LOC_Order_Order_Date: 'Order Date',

  loc_order_loc: 'LOC',
  loc_order_period: 'Period',
  loc_order_orderDateString: 'Order Date',
  loc_order_orderType: 'Order Type',
  loc_order_internalOrderStatus: 'Internal Order Status',
  loc_order_internalOrderNumber: 'Internal Order Number',
  loc_order_externalOrderNumber: 'External Order Number',
  loc_order_externalOrderStatus: 'External Order Status',
  loc_order_wholesalerCode: 'Wholesaler Code',
  loc_order_accountCode: 'Account Code',
  loc_order_legalIdCode: 'LegalId',
  loc_order_zipCode: 'ZipCode',
  loc_order_streetName: 'Street Name',
  loc_order_pharmacyName: 'Pharmacy Name',
  loc_order_noOfPharmacies: 'No Of Pharmacies',
  loc_order_brand: 'Brand',
  loc_order_ean: 'Ean',
  loc_order_skuIdentifier: 'SkuIdentifier',
  loc_order_sku: 'Sku',
  loc_order_skuPrice: 'SkuPrice',
  loc_order_requestedVolume: 'Requested Volume',
  loc_order_onInvoiceDiscount: 'On Invoice Discount',
  loc_order_registrationNumberOfPharmacies: 'Number of Pharmacies (Register)',
  loc_order_registrationGroupName: 'Business Name (Register)',
  loc_order_grossValue: 'Gross Value',
  loc_order_discountValue: 'Discount Value',
  loc_order_invoiceValue: 'Invoice Value',

  menu_text_temporary_orders: 'Temporary Orders',
  temp_id: "Order Id",
  temp_phoneNumber: "Phone Number",
  temp_loc: "loc",
  temp_wholesalerCode: "Wholesaler Code",
  temp_brandIdentifier: "Brand Identifier",
  temp_skuIdentifier: "Sku Identifier",
  temp_quantity: "Quantity",
  temp_orderDate: "Order Date",
  temp_legalId: "Legal Id",
  temp_userId: "User Id",
  temp_email: "Email",


};

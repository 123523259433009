import * as $ from "jquery";
import React, { memo } from "react";
import DataTable from "react-data-table-component";
import Paginations from "../../../../library/common/components/Pagination/Pagination";

const TableComponent = ({
  eventFunction,
  formList,
  filteredData,
  initialSort,
  initialRequest,
  handleFilterAndSorting,
  selectableRows,
  content,
}) => {
  const customSort = (rows, direction) => {
    if (rows) {
      const selector = `${rows.sort + `:` + direction}`;
      handleFilterAndSorting({ ...initialRequest, sort: [selector], page: 1 });
    }
  };
  const updateState = (id, value, dataValue, newValue) => {
    eventFunction(id, value, dataValue, newValue);
  };

  const handlePageChange = (page) => {
    $(".main-container").animate({ scrollTop: "0" });
    handleFilterAndSorting({ ...initialRequest, page });
  };
  const rowSelectCriteria = (row) => row.fat > 6;
  return (
    <>
      <DataTable
        onSort={customSort}
        columns={formList((data, value, dataValue, newValue) =>
          updateState(data, value, dataValue, newValue)
        )}
        data={filteredData?.content != null ? filteredData?.content : content}
        paginationServer
        sortServer
        defaultSortField={initialSort}
        paginationTotalRows={filteredData?.totalElements}
        selectableRows={selectableRows || false}
        selectableRowSelected={rowSelectCriteria}
      />
      <Paginations
        totalElements={filteredData?.totalElements}
        page={initialRequest?.page}
        totalPages={filteredData?.totalPages || 1}
        onChangePage={handlePageChange}
      />
    </>
  );
};

export default memo(TableComponent);

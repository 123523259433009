export const TempOrderFilterConstant = (strings) => {
  return [
    {
      placeholder: `${strings.TempOrder_text_phone}`,
      value: "",
      type: "text",
      field: "phoneNumber",
      validators: [],
      required: false,
      styleClass: "col-sm-2",
      extraProps: { maxLength: 20 },
    },
    {
      placeholder: `${strings.TempOrder_text_Date}`,
      value: "",
      type: "text",
      field: "orderDateString",
      validators: [],
      required: false,
      styleClass: "col-sm-2",
      extraProps: { maxLength: 20 },
    },
    {
      placeholder: `${strings.TempOrder_text_LegalId}`,
      value: "",
      type: "text",
      field: "legalId",
      validators: [],
      required: false,
      styleClass: "col-sm-2",
      extraProps: { maxLength: 20 },
    },
    {
      placeholder: `${strings.TempOrder_text_UserId}`,
      value: "",
      type: "text",
      field: "userId",
      validators: [],
      required: false,
      styleClass: "col-sm-2",
      extraProps: { maxLength: 20 },
    },
    {
      placeholder: `${strings.TempOrder_text_Email}`,
      value: "",
      type: "text",
      field: "email",
      validators: [],
      required: false,
      styleClass: "col-sm-2",
      extraProps: { maxLength: 50 },
    }

  ];
};

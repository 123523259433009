import React from "react";
import memoize from "memoize-one";
import { strings } from "../../../library/common/components";
import { images } from "../../../library/common/constants/ImageConstants";

const EventHistoryListJson = (permission) =>
  memoize((handleAction) => [
    {
      name: strings.event_history_eventType,
      sortable: true,
      sort: "eventType",
      selector: (row) => row?.eventType,
    },
    {
      name: strings.event_history_createdAt,
      sortable: true,
      sort: "createdAt",
      selector: (row) => row?.createdAtString,
    },

    {
      name: strings.event_history_userId,
      sortable: true,
      sort: "userId",
      selector: (row) => row?.userId,
    },
    {
      name: strings.event_history_loginDisplayCount,
      sortable: true,
      sort: "loginDisplayCount",
      selector: (row) => row?.loginDisplayCount,
    },
    {
      name: strings.event_history_message,
      sortable: true,
      sort: "message",
      selector: (row) => row?.message,
    },

  ]);

export default EventHistoryListJson;

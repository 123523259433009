import React from "react";
import Autosuggest from "react-autosuggest";
import { validateInput } from "../../../utilities/Validators";
import strings from "../Translation/Translate";
import PropTypes from "prop-types";

class Autosuggests extends React.Component {
  constructor(props) {
    super(props);
    this.searchTimeout = null;
    this.state = {
      value: "",
      suggestions: [],
      languages: [],
      error: null,
      method: false
    };
  }

  onChange = (event, { newValue, method }) => {
    const { field, validators, onChangeMethod } = this.props;
    const value = method !== "click" ? newValue : newValue.name;

    this.setState({
      value,
      method
    });
    if (this.searchTimeout) {
      clearTimeout(this.searchTimeout);
    }

    if (method !== "click") {
      this.searchTimeout = setTimeout(() => {
        this.props.handleOnChanges &&
          this.props.handleOnChanges(
            method !== "click" ? newValue : newValue.name,
            method
          );
      }, 800);
    }
    const inputError = validateInput(
      validators,
      method !== "click" ? newValue : newValue.name
    );
    this.setState({ error: inputError });
    if (onChangeMethod) {
      onChangeMethod(newValue, field, inputError);
    }
  };

  escapeRegexCharacters(str) {
    return str.replace(/[.*+?^${}()|[\]\\]/g, "\\$&");
  }

  getSuggestions(value) {
    const { suggestionsValue } = this.props;
    const escapedValue = this.escapeRegexCharacters(value.trim());

    if (escapedValue === "") {
      return [];
    }
    return suggestionsValue.filter(language => language.name);
  }

  getSuggestionValue(suggestion) {
    return { id: suggestion.id, name: suggestion.name };
  }

  renderSuggestion(suggestion) {
    return <span id={suggestion.id}>{suggestion.name}</span>;
  }

  onSuggestionsFetchRequested = ({ value }) => {
    this.setState({
      suggestions: this.getSuggestions(value)
    });
  };

  onSuggestionsClearRequested = () => {
    this.setState({
      suggestions: []
    });
  };

  render() {
    const { error } = this.state;
    const {
      suggestionsValue,
      placeholder,
      label,
      fieldError,
      hasError,
      value,
      disabled
    } = this.props;
    const inputProps = {
      placeholder: placeholder,
      value: (value && value.name !== undefined ? value.name : value) || "",
      onChange: this.onChange,
      disabled: disabled
    };
    return (
      <div className={`input-field ${fieldError}`}>
        {label !== "" ? (
          <label className="inputField-label" htmlFor="inputField">
            {label}
          </label>
        ) : (
          ""
        )}
        <Autosuggest
          suggestions={suggestionsValue}
          onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
          onSuggestionsClearRequested={this.onSuggestionsClearRequested}
          getSuggestionValue={this.getSuggestionValue}
          renderSuggestion={this.renderSuggestion}
          inputProps={inputProps}
        />
        {error && error.error && (
          <span className="text-danger">{error.message}</span>
        )}
        {!error && hasError && fieldError && (
          <span className="text-danger">{strings.requiredText}</span>
        )}
      </div>
    );
  }
}

Autosuggests.propTypes = {
  suggestionsValue: PropTypes.array.isRequired,
  placeholder: PropTypes.string,
  label: PropTypes.string,
  fieldError: PropTypes.bool,
  hasError: PropTypes.bool,
  disabled: PropTypes.bool,
  onDrop: PropTypes.func
};

Autosuggests.defaultProps = {
  placeholder: "",
  label: "",
  fieldError: false,
  hasError: false,
  disabled: false
};

export default Autosuggests;

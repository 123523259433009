import axios from "axios";
import { images } from "../../library/common/constants/ImageConstants";
import PageLoader from "../../library/PageLoader";
import { useEffect, useState } from "react";
import axiosInstance from "../../core/Axios";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import './index.css';
import { Button, strings } from "../../library/common/components";

const Checkout = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [selectedWholesaler, setSelectedWholesaler] = useState('');
    const [selectedBrand, setSelectedBrand] = useState('');
    const [selectedSku, setSelectedSku] = useState('');
    const [wholesalers, setWholeSalers] = useState([]);
    const [brands, setBrands] = useState([]);
    const [allSKus, setAllSkus] = useState([]);
    const [skusByBrand, setSkusByBrand] = useState([]);
    const [quantity, setQuantity] = useState(0);
    const [quantityError, setQuantityError] = useState('');
    const [wholesalerError, setWholesalerError] = useState(false);
    const [brandError, setBrandError] = useState(false);
    const [skuError, setSkuError] = useState(false);
    const [orderDetails, setOrderDetails] = useState([]);
    const location = useLocation();
    const screenSizeDownLg = window.innerWidth < 1350;
    const screenSizeDownMd = window.innerWidth < 900;
    const screenSizeDownSm = window.innerWidth < 600;
    const request = {
        "page": "1",
        "size": "1000",
        "filter": [],
        "sort": []
    }
    const phoneNo = location.search.substring(1).split('&')[0].substring(3);
    const getWholeSalers = async () => {
        try {
            const { data } = await axiosInstance.get(`/api/pharmacy/wholesalers?phoneNumber=${encodeURIComponent(phoneNo)}`)
            setWholeSalers(data);
        } catch { }
    }
    const getBrandsAndSkus = async () => {
        try {
            const { data } = await axiosInstance.post('/api/commercial-conditions/details', request)
            const b = data.content.map((item) => ({
                brandIdentifier: item.brandIdentifier,
                brand: item.brand
            }))
            const arrayUniqueByKey = [...new Map(b.map(item => [item['brandIdentifier'], item])).values()];
            setBrands(arrayUniqueByKey);
            setAllSkus(data.content);
        } catch { }
    }
    useEffect(() => {
        document.title = 'GSKAssist - Spain: Formulario'
        if (phoneNo === '') {
            alert('Phone number is required')
            return;
        }
        setIsLoading(true);
        Promise.all([
            getWholeSalers(),
            getBrandsAndSkus(),
        ])
            .then(() => setIsLoading(false))
            .catch(() => setIsLoading(false));
    }, []);

    const getSkusByBrand = (brand) => {
        if (brand === "") setSkusByBrand([])
        else {
            let skuArr = allSKus.filter(item => {
                if (item.brandIdentifier === brand) {
                    return {
                        "skuIdentifier": item.skuIdentifier,
                        "sku": item.sku,
                        "skuMinimumQuantity": item.skuMinimumQuantity,
                        "skuMaximumQuantity": item.skuMaximumQuantity
                    }
                }
            })
            const arrayUniqueByKey = [...new Map(skuArr.map(item => [item['skuIdentifier'], item])).values()];
            setSkusByBrand(arrayUniqueByKey)
        }
    }

    const handleSlider = e => {
        setQuantity(e.target.value)
        if (e.target.value < skusByBrand.find(s => s.skuIdentifier === selectedSku).skuMinimumQuantity) {
            setQuantityError(`El mínimo permitido son ${skusByBrand.find(s => s.skuIdentifier === selectedSku).skuMinimumQuantity} unidades.`)
        }
        else if (e.target.value > skusByBrand.find(s => s.skuIdentifier === selectedSku).skuMaximumQuantity) {
            setQuantityError(`El máximo permitido son ${skusByBrand.find(s => s.skuIdentifier === selectedSku).skuMaximumQuantity} unidades.`)
        }
        else {
            setQuantityError('')
        }
    }
    const handleReset = (finalReset) => {
        setSelectedSku('')
        setSkuError(false)
        setQuantity(0)
        setQuantityError('')
        if (finalReset) {
            document.getElementById("header").scrollIntoView({ behavior: 'smooth' });
            setSelectedBrand('')
            setBrandError(false)
            setSelectedWholesaler('')
            setWholesalerError(false)
            setOrderDetails([])
        }
    }
    const handleSubmit = async (e) => {
        try {
            e.preventDefault();
            let queryStringObj = {};
            if (location.search === '') {
                queryStringObj = {
                    ph: "",
                    legalId: ""
                }
            } else {
                location.search.substring(1).split('&').forEach(i => {
                    let a = i.split('=')
                    queryStringObj = {
                        ...queryStringObj,
                        [a[0]]: a[1]
                    }
                })
            }
            if (selectedWholesaler === '') setWholesalerError(true);
            else {
                const req = orderDetails.map(o => {
                    let { sku, ...rest } = {
                        ...o,
                        phoneNumber: queryStringObj['ph'],
                        legalId: queryStringObj['legalId'],
                        loc: "Spain",
                        wholesalerCode: document.getElementById('wholesaler')?.value,
                        skuIdentifier: o.sku?.skuIdentifier
                    }
                    return rest;
                })
                const payload = {
                    orders: req
                }
                setIsLoading(true);
                const { status, data } = await axiosInstance.post('/api/pharmacy-users/orders/checkout', payload)
                if (status === 200) {
                    // alert('Tus pedidos se encuentran en proceso. Ya puedes Volver a GSKAssist')
                    window.parent.postMessage(JSON.stringify({
                        event_code: "confirm_button_clicked",
                        data: { orderdata: req }
                    }), '*');
                    handleReset(true)
                }
            }
        } catch {
            alert('Failed to place order')
        } finally {
            setIsLoading(false)
        }
    }
    const handleAdd = async (e) => {
        e.preventDefault();
        let formValid = true;
        if (selectedBrand === '') {
            setBrandError(true);
            formValid = false;
        }
        if (selectedSku === '') {
            setSkuError(true)
            formValid = false;
        }
        if (quantityError !== '') {
            formValid = false;
        }
        if (formValid) {
            const request = {
                brandIdentifier: selectedBrand,
                sku: allSKus.find(i => i.skuIdentifier === selectedSku),
                quantity: parseInt(quantity),
            }
            const newArray = [...orderDetails];
            newArray.push(request)
            setOrderDetails(newArray.reverse())
            handleReset(false)
        }
    }

    return (
        <div style={{ overflowY: 'auto', backgroundColor: '#f0f0f0' }}>
            <div style={{
                height: '100vh',
                padding: screenSizeDownSm ? '25px' : '30px 50px',
            }}>
                {isLoading && <PageLoader />}
                <div style={{ fontSize: '28px', color: '#f07a19', paddingBottom: '8px' }} id='header'>Solicitud de Transfer</div>
                <div style={{ padding: '20px', backgroundColor: '#ffffff' }}>
                    <form>
                        <div style={{ display: 'flex', gap: '30px', width: '100%' }}>
                            <div style={{ display: 'flex', flexDirection: 'column', gap: '10px', flex: 1 }}>
                                <div><label for="brand">Marca:</label></div>
                                {brandError && <div></div>}
                                <div><label for="sku">Presentación:</label></div>
                                {skuError && <div></div>}
                                <div><label for="quantity">Seleccionar Cantidad:</label></div>
                            </div>
                            <div style={{ display: 'flex', flexDirection: 'column', gap: '10px', flex: 10, width: screenSizeDownSm ? '50%' : '60%' }}>
                                <select id="brand" name="brand" size="1" value={selectedBrand}
                                    style={{ padding: '3px 6px ' }} onChange={(e) => {
                                        setSelectedBrand(e.target.value)
                                        setSelectedSku('')
                                        setQuantity(0)
                                        getSkusByBrand(e.target.value)
                                        e.target.value === '' ? setBrandError(true) : setBrandError(false)
                                    }}
                                >
                                    <option value="">Selecciona la marca</option>
                                    {brands.map(ws => (
                                        <option key={ws.brandIdentifier} value={ws.brandIdentifier}>{ws.brand}</option>
                                    ))}
                                </select>
                                {brandError && <div style={{ color: 'red', fontSize: '12px' }}><i> Por favor seleccione un marca.</i></div>}
                                <select id="sku" name="sku" size="1"
                                    value={selectedSku}
                                    disabled={selectedBrand === ''}
                                    onChange={(e) => {
                                        e.target.value === '' ? setSkuError(true) : setSkuError(false)
                                        setSelectedSku(e.target.value)
                                        setQuantity(e.target.value === '' ? 0 : skusByBrand.find(s => s.skuIdentifier === e.target.value).skuMinimumQuantity)
                                    }}
                                    style={{ padding: '3px 6px' }}
                                >
                                    <option value="">Selecciona la presentación</option>
                                    {skusByBrand.map(ws => (
                                        <option key={ws.skuIdentifier} value={ws.skuIdentifier}>{ws.sku}</option>
                                    ))}
                                </select>
                                {skuError && <div style={{ color: 'red', fontSize: '12px' }}><i> Por favor seleccione un presentación.</i></div>}
                                <div style={{ display: 'flex', alignItems: 'center', gap: '8px', width: '100%', flexDirection: 'column' }}>
                                    <input disabled={selectedSku === ''}
                                        value={quantity === '' ? 0 : quantity}
                                        type="range"
                                        min={selectedSku === '' ? 0 : skusByBrand.find(s => s.skuIdentifier === selectedSku).skuMinimumQuantity}
                                        max={selectedSku === '' ? 10 : skusByBrand.find(s => s.skuIdentifier === selectedSku).skuMaximumQuantity}
                                        id='quantity'
                                        name='quantity'
                                        onChange={handleSlider}
                                        defaultValue="0"
                                        style={{ width: '100%' }}
                                    />
                                    <div style={{ width: '100%' }}>
                                        <input type="number" id='quantityVal' name='quantity' disabled={selectedSku === ''}
                                            min={selectedSku === '' ? 0 : skusByBrand.find(s => s.skuIdentifier === selectedSku).skuMinimumQuantity}
                                            max={selectedSku === '' ? 10 : skusByBrand.find(s => s.skuIdentifier === selectedSku).skuMaximumQuantity}
                                            value={quantity}
                                            onChange={e => {
                                                handleSlider(e)
                                            }
                                            }
                                            style={{ width: '100%' }}
                                        />
                                        <div style={{ color: 'red', fontSize: '12px' }}><i>{quantityError}</i></div> </div>
                                </div>
                            </div>
                        </div>
                        <br />
                        <div>
                            <button style={{ margin: 'auto', fontSize: '12px' }} onClick={handleAdd}>AGREGAR</button>
                        </div>
                    </form>
                </div>
                {
                    orderDetails.length > 0 &&
                    <>
                        <div style={{ fontSize: '28px', color: '#f07a19', padding: '24px 0px 8px 0px' }}>Resumen del Pedido</div>
                        <div style={{ padding: '20px', backgroundColor: '#ffffff' }}>
                            <div style={{ display: 'flex', gap: '30px', width: '100%', paddingBottom: '16px' }}>
                                <div style={{ display: 'flex', flexDirection: 'column', gap: '10px', flex: 1 }}>
                                    <div><label for="wholesaler">Mayorista:</label></div>
                                    {wholesalerError && <div style={{ height: '16px' }}></div>}
                                    <div>Fecha:</div>
                                </div>
                                <div style={{ display: 'flex', flexDirection: 'column', gap: '10px', flex: 10, width: screenSizeDownSm ? '50%' : '60%' }}>
                                    <select id="wholesaler" name="wholesaler" size="1" style={{ padding: '3px 6px' }} value={selectedWholesaler}
                                        onChange={e => {
                                            setSelectedWholesaler(e.target.value)
                                            e.target.value === '' ? setWholesalerError(true) : setWholesalerError(false)
                                        }}
                                    >
                                        <option value="">Selecciona el mayorista</option>
                                        {wholesalers.map(ws => (
                                            <option key={ws.code} value={ws.code}>{ws.name}</option>
                                        ))}
                                    </select>
                                    {wholesalerError && <div style={{ color: 'red', fontSize: '12px' }}><i>Por favor seleccione un mayorista.</i></div>}
                                    <span style={{ padding: '3px' }}>{new Date().toISOString().split('T')[0]}</span>
                                </div>
                            </div>
                            <table>
                                <tr>
                                    <th style={{ width: '40vw' }}>Presentación</th>
                                    <th style={{ width: '40vw' }}>Cantidad</th>
                                    <th style={{ border: 0, backgroundColor: 'transparent' }}></th>
                                </tr>
                                {orderDetails.map((o, idx) => <tr>
                                    <td>{o.sku?.sku}</td>
                                    <td>{o.quantity}</td>
                                    <td style={{ border: 0, backgroundColor: 'transparent' }}>
                                        <img src={images.deleteIcon} alt="delete icon" style={{
                                            cursor: "pointer", height: '20px', paddingLeft: '10px'
                                        }} onClick={() => {
                                            const filter = orderDetails.filter((item, index) => index !== idx)
                                            setOrderDetails(filter)
                                        }} />
                                    </td>
                                </tr>
                                )}
                            </table>
                            <div style={{
                                display: 'flex', alignItems: 'center', gap: '40px', marginTop: '20px', justifyContent: 'center'
                            }}>
                                <button style={{ fontSize: '12px' }} onClick={handleSubmit}>CONFIRMAR</button>
                                <button style={{ fontSize: '12px' }} onClick={(e) => {
                                    handleReset(true);
                                    window.parent.postMessage(JSON.stringify({
                                        event_code: "cancel_button_clicked",
                                        data: {}
                                    }), '*');
                                    // alert('Tus pedidos NO SE HAN ENVIADO. Puedes Volver a GSKAssist para mas opciones')
                                }}>CANCELAR</button>
                            </div>
                        </div>
                    </>
                }
            </div >
        </div >
    )
}

export default Checkout;
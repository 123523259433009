export const EventConfigurationFilterConstant = (strings) => {
  return [
    {
      placeholder: `${strings.event_config_loc}`,
      value: "",
      type: "text",
      field: "loc",
      validators: [],
      required: false,
      styleClass: "col-sm-2",
      extraProps: { maxLength: 20 },
    },
    {
      placeholder: `${strings.event_config_event}`,
      value: "",
      type: "text",
      field: "eventType",
      validators: [],
      required: false,
      styleClass: "col-sm-2",
      extraProps: { maxLength: 20 },
    },
    {
      placeholder: `${strings.event_config_content_type}`,
      value: "",
      type: "text",
      field: "contentType",
      validators: [],
      required: false,
      styleClass: "col-sm-2",
      extraProps: { maxLength: 20 },
    },
  ];
};

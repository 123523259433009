import React, { memo } from "react";
import Pagination from "react-js-pagination";
import "./paginations.scss";

const Paginations = ({ page, totalElements, onChangePage, totalPages }) => {
  const handlePageChange = pageNumber => {
    onChangePage(pageNumber);
  };
  const arrowIcons = icon => {
    return <i className={`fa fa-${icon}`} />;
  };
  return (
    <div>
      {totalPages > 1 && (
        <div className="mainPagination pt-3">
          <div className="pagination-btn_wrapper d-flex flex-column align-items-center">
            <Pagination
              prevPageText={arrowIcons("angle-left")}
              nextPageText={arrowIcons("angle-right")}
              firstPageText={arrowIcons("angle-double-left")}
              lastPageText={arrowIcons("angle-double-right")}
              itemClass="page-item"
              linkClass="page-link"
              activePage={page}
              itemsCountPerPage={10}
              totalItemsCount={totalElements}
              pageRangeDisplayed={5}
              onChange={handlePageChange}
            />
            <p>Total Results {totalElements}</p>
          </div>
        </div>
      )}
    </div>
  );
};

export default memo(Paginations);

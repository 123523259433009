import { storeConstants } from "../../library/common/constants";

const initialState = {
  OrderList: [],
  userFilterCondition: {
    page: 1,
    filter: [],
    sort: [],
    graphql: null,
    size: 10,
    filterValue: {},
  },
};

const TempOrderReducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case storeConstants.TEMP_ORDER_LIST:
      return {
        ...state,
        TempOrderList: action.payload,
      };
    case storeConstants.PAGE_NUMBER_USER:
      return {
        ...state,
        userFilterCondition: action.payload,
      };
    default:
      return state;
  }
};

export default TempOrderReducer;

import React, { Component, createRef } from "react";
import { connect } from "react-redux";
import * as $ from "jquery";
import { NavLink } from "react-router-dom";
import { TableComponent } from "../../../library/common/components";
import axiosInstance from "../../../core/Axios";
import { URLS } from "../../../library/common/constants";
import { fetchTempOrderList, saveTempOrderFilter } from "../TempOrderAction";
import {
  ActionMessage,
  Form,
  Loader,
  strings,
} from "../../../library/common/components";
import DeleteRow from "../../../library/common/components/Delete";
import TempOrderListJson from "./TempOrderListJson";
import { TempOrderFilterConstant } from "./Frame/TempOrderListFilterConstant";
import {
  handleAllFilter,
  isEmpty,
  removeErrorFieldsFromValues,
} from "../../../library/utilities";
import TempOrderSearchFilterCriteria from "./Frame/TempOrderSearchFilterCriteria";
// import UploadCsv from "../../../library/common/components/UploadCsv/UploadCsv";
import DownloadCsv from "../../../library/common/components/DownloadCsv/DownloadCsv";

export class TempOrderList extends Component {
  formRef = createRef();

  constructor(props) {
    super(props);
    this.__searchTimeout = null;
    this.state = {
      rowId: "",
      isLoading: false,
      isDeleted: null,
      initialRequest: {
        page: this.props.TempOrderFilterCondition?.page,
        size: 10,
        filter: [],
        sort: [],
        graphql: null,
      },
      multiFilterOperation: "AND",
      filters: {},
      messageBox: {
        display: false,
        type: "",
        message: "",
      },
    };
  }


  componentDidMount() {
    document.title = strings.menu_text_temporary_orders;
    const {
      TempOrderFilterCondition,
    } = this.props;
    const { initialRequest } = this.state;

    const isTempOrderObjectIsEmpty = isEmpty(TempOrderFilterCondition?.filterValue);
    isTempOrderObjectIsEmpty > 0
      ? this.handleTempOrderFilter(TempOrderFilterCondition?.filterValue)
      : this.setState(
        {
          initialRequest: {
            ...initialRequest,
            page: TempOrderFilterCondition?.page,
          },
        },
        () => {
        }
      );
  }

  async componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevState.initialRequest !== this.state.initialRequest) {
      const { fetchTempOrderList } = this.props;
      const { initialRequest } = this.state;
      this.setState({ isLoading: true });
      await fetchTempOrderList(initialRequest);
      this.setState({ isLoading: false });
    }
  }

  // EditItem = async (id) => {
  //   this.props.history.push(`/edit-Order/${id}`);
  // };

  // userToggle = (index) => {
  //   this.toggleUser(index);
  // };

  // deleteItem = (index, isDeleted) => {
  //   $(`#delete-modal`).modal("show");
  //   this.setState({ rowId: index, isDeleted: isDeleted });
  // };

  // updateState = (id, value, deletedFlag) => {
  //   if (value === "edit") {
  //     this.EditItem(id);
  //   } else if (value === "toggleUser") {
  //     this.userToggle(id);
  //   } else {
  //     this.deleteItem(id, deletedFlag);
  //   }
  // };

  // toggleUser = (value) => {
  //   this.setState({ isLoading: true });
  //   axiosInstance
  //     .put(URLS.toggleUsers(value))
  //     .then(({ status }) => {
  //       if (status === 200) {
  //         // this.setActionMessage(true, 'Success', 'User Successfully updated');
  //         // this.fetchInitialData();
  //         const { fetchTempOrderList } = this.props;
  //         const { initialRequest } = this.state;
  //         fetchTempOrderList(initialRequest);
  //         this.setState({ isLoading: false });
  //       }
  //     })
  //     .catch((err) => {
  //       this.setState({ isLoading: false });
  //       if (err.hasOwnProperty("response")) {
  //         this.setActionMessage(true, "Error", err.response.data.message);
  //       } else {
  //         this.setActionMessage(true, "Error", "Oops! Something went wrong.");
  //       }
  //     });
  // };

  setActionMessage = (display = false, type = "", message = "") => {
    let setActionMessage = {
      display: display,
      type: type,
      message: message,
    };
    this.setState({ messageBox: setActionMessage });
  };
  handleFilterAndSorting = (filteredValue) => {
    this.setState({ initialRequest: filteredValue });
    const { TempOrderFilterCondition, saveTempOrderFilter } = this.props;
    saveTempOrderFilter({ ...TempOrderFilterCondition, page: filteredValue.page });
  };

  handleSearch = (value, name, finalInputData) => {
    const { filters } = this.state;
    const newFilters = { ...filters };
    newFilters[name] = {
      value,
      operation: "cic",
    };
    this.setState({ filters: newFilters }, () => {
      this.handleTempOrderFilter(finalInputData);
    });
  };

  handleTempOrderFilter = async (finalInputData) => {
    const { TempOrderFilterCondition, saveTempOrderFilter } = this.props;
    saveTempOrderFilter({ ...TempOrderFilterCondition, filterValue: finalInputData });
    this.setState(
      {
        filters: {
          loc: { value: finalInputData?.loc || "" },
          phoneNumber: { value: finalInputData?.phoneNumber || "" },
          orderDateString: { value: finalInputData?.orderDateString || "" },
          legalId: { value: finalInputData?.legalId || "" },
          userId: { value: finalInputData?.userId || "" },
          email: { value: finalInputData?.email || "" },
        },
      },
      () => {
        this.handleTempOrderUpdatedFilter();
      }
    );
  };

  handleTempOrderUpdatedFilter = async () => {
    const { filters, initialRequest, multiFilterOperation } = this.state;
    if (filters) {
      const filteredValue = await handleAllFilter(
        filters,
        multiFilterOperation,
        "cic"
      );
      if (this.__searchTimeout) {
        clearTimeout(this.__searchTimeout);
      }
      this.__searchTimeout = setTimeout(() => {
        this.setState({
          initialRequest: { ...initialRequest, filter: filteredValue, page: 1 },
        });
      }, 500);
    }
  };

  handleFormChange = (field, value, inputData) => {
    let fieldValue = value;
    const fields = { field: fieldValue };
    let initialData = { ...inputData, ...fields };
    const finalInputData = removeErrorFieldsFromValues(initialData);
    this.handleSearch(fieldValue, field, finalInputData);
  };

  handleClearFilter = () => {
    const { TempOrderFilterCondition, saveTempOrderFilter } = this.props;
    this.setState(
      {
        initialRequest: {
          page: 1,
          size: 10,
          filter: [],
          sort: [],
          graphql: null,
        },
        filters: {},
      },
      () => {
        saveTempOrderFilter({
          ...TempOrderFilterCondition,
          filterValue: this.state.filters,
          page: 1,
        });
      }
    );
    this.formRef.resetForm();
  };

  updatedFilter = () => {
    const { initialRequest } = this.state;
    const newPage = initialRequest.page > 1 ? initialRequest.page - 1 : 1;
    this.setState({ initialRequest: { ...initialRequest, page: newPage } });
  };

  filterArray = () => {
    // // let locValue =
    // // filters.loc?.value.length > 0 ? `loc:cic:${filters.loc.value}` : "";
    // let brandValue =
    //   filters.brand?.value.length > 0 ? `brand:cic:${filters.brand.value}` : "";
    // let skuValue =
    //   filters.sku?.value.length > 0 ? `sku:cic:${filters.sku.value}` : "";
    // let arr = [];
    // if (brandValue.length > 0 && skuValue.length > 0) {
    //   arr = [brandValue, "and", skuValue];
    // } else if (brandValue.length === 0 && skuValue.length === 0) {
    //   arr = [];
    // } else if (brandValue.length > 0 && skuValue.length === 0) {
    //   arr = [brandValue];
    // } else {
    //   arr = [skuValue];
    // }
    // return arr;
    const { filters, multiFilterOperation } = this.state;
    let filteredValue = [];
    if (Object.keys(filters).length > 0) {
      filteredValue = handleAllFilter(
        filters,
        multiFilterOperation,
        "cic"
      );
    }
    return filteredValue
  };

  render() {
    const { rowId, isLoading, messageBox, initialRequest, isDeleted, filters } =
      this.state;
    const { permission, TempOrderList, fetchTempOrderList, TempOrderFilterCondition } =
      this.props;

    console.log('TempOrderList: ', TempOrderList)
    return (
      <div className="main-content table-container">
        <div>
          {messageBox.display && (
            <ActionMessage
              message={messageBox.message}
              type={messageBox.type}
              styleClass={messageBox.styleClass}
            />
          )}
        </div>
        <div className="row mt-3">
          <div className="col-sm-12 table-wrapper">
            <div className="d-flex justify-content-between align-items-center topFilter">
              <div className="d-flex">
                <h3 style={{ color: "#F36633", fontWeight: "700" }}>
                  {strings.menu_text_temporary_orders}
                </h3>
                <button
                  title={strings.search_filter_text}
                  className="btn advanceSearch mt-0"
                  type="button"
                  data-toggle="collapse"
                  data-target="#collapseExample"
                  aria-expanded="false"
                  aria-controls="collapseExample"
                >
                  <i className="fa fa-filter" aria-hidden="true" />
                </button>
                <button
                  data-toggle="tooltip"
                  title={strings.clear_filter_text}
                  className="btn advanceSearch ml-2"
                  onClick={this.handleClearFilter}
                >
                  <i className="fa fa-refresh" aria-hidden="true" />
                </button>

                {/* <UploadCsv apiUrl={URLS.uploadOrders} title="Upload Orders Excel" /> */}
                <DownloadCsv
                  apiUrl={URLS.downloadTempOrderCSV}
                  title="Download Temporary Orders Excel"
                  //fileName="orderlist"
                  filters={() => this.filterArray()}
                />
              </div>
              {/* {permission.includes("ADD_USER") && (
                <NavLink
                  title={strings.Order_button_add}
                  className="btn btn-success font-weight-bold btn-main"
                  to="/add-Order"
                >
                  <i className="fa fa-plus" />
                </NavLink>
              )} */}
            </div>
            {/* <div className="selectedFilter">
              <div className="d-flex filterTextWrapper">
                <div className="badges">
                  <label>Page : </label>
                  {
                    <span className="filterBadge">
                      {initialRequest.page || 1}
                    </span>
                  }
                </div>
                {TempOrderFilterCondition?.filterValue !== undefined && (
                  <TempOrderSearchFilterCriteria
                    filterCondition={TempOrderSearchFilterCriteria?.filterValue}
                  />
                )}
              </div>
            </div> */}
            <div
              className="collapse mb-1 mt-4 searchFilter"
              id="collapseExample"
            >
              <div className="card card-body">
                <span className="search_filter_text">
                  {strings.search_filter_text}
                </span>
                <div className="searchWrapper">
                  <Form
                    values={TempOrderFilterCondition?.filterValue}
                    onChange={this.handleFormChange}
                    model={TempOrderFilterConstant(strings, true)}
                    ref={(el) => (this.formRef = el)}
                  />
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-sm-12 table-wrapper">
                {isLoading && <Loader />}
                <TableComponent
                  formList={TempOrderListJson(permission)}
                  initialSort="username"
                  filteredData={TempOrderList}
                  permission={permission}
                  eventFunction={this.updateState}
                  users={TempOrderList}
                  initialRequest={initialRequest}
                  handleFilterAndSorting={this.handleFilterAndSorting}
                />
              </div>
            </div>
            {/* <DeleteRow
              updatedFilter={this.updatedFilter}
              totalSize={OrderList?.numberOfElements}
              bodyText={isDeleted === false && strings.OrderDeleteMessage}
              initialRequest={initialRequest}
              deleteUrl={URLS.deleteOrder(rowId)}
              updatedList={fetchOrderList}
            /> */}
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ authReducer, TempOrder }) => {
  return {
    permission: authReducer.token.privileges,
    TempOrderList: TempOrder.TempOrderList,
    TempOrderFilterCondition: TempOrder.TempOrderFilterCondition,
  };
};
export default connect(mapStateToProps, {
  fetchTempOrderList,
  saveTempOrderFilter,
})(TempOrderList);

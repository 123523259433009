import PropTypes from "prop-types";
import React, { useState } from "react";
import CreatableSelect from "react-select/creatable";
import { validateInput } from "../../../utilities/Validators";
import strings from "../Translation/Translate";

const CreateDropdown = ({
  value,
  field,
  label,
  testId,
  options,
  isMulti,
  validators,
  placeholder,
  isSearchable,
  isClearable,
  isDisabled,
  onChange,
  closeMenuOnSelect,
  helpText,
  isHelp,
  textHint,
  fieldError,
  hasError,
  noOptionsMessage,
  extraProps
}) => {
  const [error, setError] = useState(null);

  const handleChange = selectedOption => {
    const inputError = validateInput(validators, selectedOption);
    setError(inputError);
    onChange(selectedOption, field, inputError);
  };

  return (
    <div className="app-dropdown">
      {label !== "" ? (
        <label className="inputField-label" htmlFor="inputField">
          {label}
          {isHelp && (
            <span
              style={{ display: "none" }}
              className="help"
              data-toggle="tooltip"
              data-placement="top"
              title={helpText}
            >
              <i className="ml-2 fa fa-info-circle" />
            </span>
          )}
        </label>
      ) : null}
      <CreatableSelect
        data-test={testId}
        value={value}
        options={options}
        isMulti={isMulti}
        isDisabled={isDisabled}
        placeholder={placeholder}
        isSearchable={isSearchable}
        noOptionsMessage={() => noOptionsMessage}
        onChange={handleChange}
        closeMenuOnSelect={closeMenuOnSelect}
        isClearable={isClearable}
        {...extraProps}
      />
      {error && error.error && (
        <span className="text-danger">{error.message}</span>
      )}
      {!error && hasError && fieldError && (
        <span className="text-danger">{strings.requiredText}</span>
      )}
    </div>
  );
};

export default CreateDropdown;

CreateDropdown.propTypes = {
  options: PropTypes.array,
  isMulti: PropTypes.bool,
  placeholder: PropTypes.string,
  isClearable: PropTypes.bool,
  noOptionsMessage: PropTypes.string,
  isSearchable: PropTypes.bool,
  hasError: PropTypes.bool,
  fieldError: PropTypes.bool,
  isDisabled: PropTypes.bool,
  validators: PropTypes.array,
  value: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.string,
    PropTypes.array
  ]),
  testId: PropTypes.string,
  field: PropTypes.string,
  label: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  closeMenuOnSelect: PropTypes.bool,
  helpText: PropTypes.string,
  extraProps: PropTypes.object,
  textHint: PropTypes.string,
  isHelp: PropTypes.bool
};

CreateDropdown.defaultProps = {
  isMulti: false,
  placeholder: "Please select",
  noOptionsMessage: "No options",
  isClearable: false,
  isSearchable: false,
  isDisabled: false,
  validators: [],
  hasError: false,
  fieldError: false,
  testId: "",
  field: "",
  value: {},
  extraProps: {},
  label: "",
  closeMenuOnSelect: true,
  helpText: "",
  isHelp: true,
  textHint: ""
};

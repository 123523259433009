import React from "react";
import memoize from "memoize-one";
import { strings } from "../../../library/common/components";
import { images } from "../../../library/common/constants/ImageConstants";

const ManualOfferListJson = permission =>
  memoize(handleAction => [
    {
      name: strings.ManualOffer_column_loc,
      sortable: true,
      sort: 'loc',
      selector: row => row?.loc
    },
    {
      name: strings.ManualOffer_column_period,
      sortable: true,
      sort: 'period',
      selector: row => row?.period.period
    },
    {
      name: strings.ManualOffer_column_client_code,
      sortable: true,
      sort: 'clientPosCode',
      selector: row => row?.clientPosCode
    },
    {
      name: strings.ManualOffer_column_ean,
      sortable: true,
      sort: 'ean',
      selector: row => row?.ean,
    },
    {
      name: strings.ManualOffer_column_brand_hash,
      sortable: true,
      sort: 'brandIdentifier',
      selector: row => row?.brandIdentifier,
    },
    {
      name: strings.ManualOffer_column_brand,
      sortable: true,
      sort: 'brand',
      selector: row => row?.brand,
    },
    {
      name: strings.ManualOffer_column_sku_hash,
      sortable: true,
      sort: 'skuIdentifier',
      selector: row => row?.skuIdentifier,
    },
    {
      name: strings.ManualOffer_column_sku,
      sortable: true,
      sort: 'sku',
      selector: row => row?.sku,
    },
    {
      name: strings.ManualOffer_column_range1,
      sortable: true,
      sort: 'rangeOneVolume',
      selector: row => row?.rangeOneVolume,
    },
    {
      name: strings.ManualOffer_column_range2,
      sortable: true,
      sort: 'rangeTwoVolume',
      selector: row => row?.rangeTwoVolume
    },
    {
      name: strings.ManualOffer_column_range3,
      sortable: true,
      sort: 'rangeThreeVolume',
      selector: row => row?.rangeThreeVolume
    },
    {
      name: strings.ManualOffer_column_range4,
      sortable: true,
      sort: 'rangeFourVolume',
      selector: row => row?.rangeFourVolume
    },
    {
      name: strings.ManualOffer_column_range5,
      sortable: true,
      sort: 'rangeFiveVolume',
      selector: row => row?.rangeFiveVolume,
    },
    {
      name: strings.ManualOffer_column_max,
      sortable: true,
      sort: 'maxVolume',
      selector: row => row?.maxVolume,
    },
    {
      name: strings.ManualOffer_column_no_discount,
      sortable: true,
      sort: 'noDiscountPercentage',
      selector: row => row?.noDiscountPercentage,
    },
    {
      name: strings.ManualOffer_column_range0_discount,
      sortable: true,
      sort: 'onInvoiceRangeZeroDiscount',
      selector: row => row?.onInvoiceRangeZeroDiscount,
    },
    {
      name: strings.ManualOffer_column_range1_discount,
      sortable: true,
      sort: 'onInvoiceRangeOneDiscount',
      selector: row => row?.onInvoiceRangeOneDiscount,
    },
    {
      name: strings.ManualOffer_column_range2_discount,
      sortable: true,
      sort: 'onInvoiceRangeTwoDiscount',
      selector: row => row?.onInvoiceRangeTwoDiscount,
    },
    {
      name: strings.ManualOffer_column_range3_discount,
      sortable: true,
      sort: 'onInvoiceRangeThreeDiscount',
      selector: row => row?.onInvoiceRangeThreeDiscount
    },
    {
      name: strings.ManualOffer_column_range4_discount,
      sortable: true,
      sort: 'onInvoiceRangeFourDiscount',
      selector: row => row?.onInvoiceRangeFourDiscount
    },
    {
      name: strings.ManualOffer_column_range5_discount,
      sortable: true,
      sort: 'onInvoiceRangeFiveDiscount',
      selector: row => row?.onInvoiceRangeFiveDiscount
    },
    {
      name: strings.ManualOffer_column_range0_pay_term,
      sortable: true,
      sort: 'onInvoiceRangeZeroPaymentTerms',
      selector: row => row?.onInvoiceRangeZeroPaymentTerms,
    },
    {
      name: strings.ManualOffer_column_range1_pay_term,
      sortable: true,
      sort: 'onInvoiceRangeOnePaymentTerms',
      selector: row => row?.onInvoiceRangeOnePaymentTerms,
    },
    {
      name: strings.ManualOffer_column_range2_pay_term,
      sortable: true,
      sort: 'onInvoiceRangeTwoPaymentTerms',
      selector: row => row?.onInvoiceRangeTwoPaymentTerms,
    },
    {
      name: strings.ManualOffer_column_range3_pay_term,
      sortable: true,
      sort: 'onInvoiceRangeThreePaymentTerms',
      selector: row => row?.onInvoiceRangeThreePaymentTerms,
    },
    {
      name: strings.ManualOffer_column_range4_pay_term,
      sortable: true,
      sort: 'onInvoiceRangeFourPaymentTerms',
      selector: row => row?.onInvoiceRangeFourPaymentTerms,
    },
    {
      name: strings.ManualOffer_column_range5_pay_term,
      sortable: true,
      sort: 'onInvoiceRangeFivePaymentTerms',
      selector: row => row?.onInvoiceRangeFivePaymentTerms,
    },
    {
      name: strings.ManualOffer_column_range0_discount,
      sortable: true,
      sort: 'offInvoiceRangeZeroDiscount',
      selector: row => row?.offInvoiceRangeZeroDiscount
    },
    {
      name: strings.ManualOffer_column_range1_discount,
      sortable: true,
      sort: 'offInvoiceRangeOneDiscount',
      selector: row => row?.offInvoiceRangeOneDiscount
    },
    {
      name: strings.ManualOffer_column_range2_discount,
      sortable: true,
      sort: 'offInvoiceRangeTwoDiscount',
      selector: row => row?.offInvoiceRangeTwoDiscount
    },
    {
      name: strings.ManualOffer_column_range3_discount,
      sortable: true,
      sort: 'offInvoiceRangeThreeDiscount',
      selector: row => row?.offInvoiceRangeThreeDiscount,
    },
    {
      name: strings.ManualOffer_column_range4_discount,
      sortable: true,
      sort: 'offInvoiceRangeFourDiscount',
      selector: row => row?.offInvoiceRangeFourDiscount,
    },
    {
      name: strings.ManualOffer_column_range5_discount,
      sortable: true,
      sort: 'offInvoiceRangeFiveDiscount',
      selector: row => row?.offInvoiceRangeFiveDiscount,
    },
    // {
    //   name: strings.ManualOffer_column_rangeZeroPriceReference,
    //   sortable: true,
    //   sort: 'priceReferenceRangeZero',
    //   selector: row => row?.priceReferenceRangeZero,
    // },
    // {
    //   name: strings.ManualOffer_column_rangeOnePriceReference,
    //   sortable: true,
    //   sort: 'priceReferenceRangeOne',
    //   selector: row => row?.priceReferenceRangeOne,
    // },
    // {
    //   name: strings.ManualOffer_column_rangeTwoPriceReference,
    //   sortable: true,
    //   sort: 'priceReferenceRangeTwo',
    //   selector: row => row?.priceReferenceRangeTwo,
    // },
    // {
    //   name: strings.ManualOffer_column_rangeThreePriceReference,
    //   sortable: true,
    //   sort: 'priceReferenceRangeThree',
    //   selector: row => row?.priceReferenceRangeThree,
    // },
    // {
    //   name: strings.ManualOffer_column_rangeFourPriceReference,
    //   sortable: true,
    //   sort: 'priceReferenceRangeFour',
    //   selector: row => row?.priceReferenceRangeFour,
    // },
    // {
    //   name: strings.ManualOffer_column_rangeFivePriceReference,
    //   sortable: true,
    //   sort: 'priceReferenceRangeFive',
    //   selector: row => row?.priceReferenceRangeFive,
    // },
    // {
    //   cell: row => (
    //     <div>
    //       {row.currentUser ? (
    //         ""
    //       ) : (
    //         <div className="lastIconRow">
    //           {permission.includes("BLOCK_USER") && (
    //             <span
    //               onClick={() => handleAction(row.id, "toggleUser")}
    //               title={`${row.enabled
    //                 ? strings.ManualOffer_button_active_user
    //                 : strings.ManualOffer_button_in_active_user
    //                 }`}
    //               className="innerImgBox"
    //             >
    //               <img
    //                 src={
    //                   row.enabled
    //                     ? images.activeToggleIcon
    //                     : images.inActiveToggleIcon
    //                 }
    //                 alt="toggle icon"
    //               />
    //               {/* <i className={`fa fa-${row.activityStatus ? 'toggle-on text-success' : 'toggle-off text-danger'}`}/> */}
    //             </span>
    //           )}

    //           <span
    //             className="innerImgBox"
    //             title={strings.common_button_edit}
    //             onClick={() => handleAction(row.id, "edit")}
    //           >
    //             <img src={images.editIcon} alt="edit icon" />
    //           </span>

    //           {permission.includes("DELETE_USER") && (
    //             <span
    //               className="innerImgBox"
    //               title={strings.common_button_delete}
    //               onClick={() =>
    //                 handleAction(row.id, "delete", row.allowedToDelete)
    //               }
    //             >
    //               <img src={images.deleteIcon} alt="delete icon" />
    //             </span>
    //           )}
    //         </div>
    //       )}
    //     </div>
    //   )
    // }
  ]);

export default ManualOfferListJson;

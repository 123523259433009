import { fetchFromStorage } from "./Storage";
import { identifiers } from "../common/constants";
import axios from "axios";
import Config from "../../core/Config";

/**
 * This function will create data with supported format for react-select dropdown.
 * @param data - array of data
 * @param keys - e.g: ['id', 'name'] which can be used to create format for dropdown
 * @returns {[]}
 */
// import {fetchFromStorage} from './Storage';

// const token = fetchFromStorage('token');
export const createDropdownData = (data = [], keys = []) => {
  const createdArray = [];
  if (data && data.length) {
    data.forEach((item) => {
      if (item) {
        createdArray.push({
          value: item[keys[0]],
          label: item[keys[1]],
        });
      }
    });
  }
  return createdArray;
};

/**
 * This function will extract `value` from selected multiple dropdown. This will only used when Dropdown component has isMulti: true
 * @param values - selected values from dropdown
 * @returns {[]}
 */
export const fetchIdsFromSelectedDropdownValues = (values = []) => {
  const extractedValue = [];
  if (values && values.length) {
    values.forEach((item) => {
      extractedValue.push(item.value);
    });
  }
  return extractedValue;
};
export const fetchIdsFromSelectedDropdownValuesId = (values = []) => {
  const extractedValue = [];
  if (values && values.length) {
    values.forEach((item) => {
      extractedValue.push(item.id);
    });
  }
  return extractedValue;
};
export const fetchLabelsFromSelectedDropdownValues = (values = []) => {
  const extractedValue = [];
  if (values && values.length) {
    values.forEach((item) => {
      extractedValue.push(item.label);
    });
  }
  return extractedValue.join(",");
};
export const fetchLabelsDropdownValues = (values = []) => {
  const extractedValue = [];
  if (values && values.length) {
    values.forEach((item) => {
      extractedValue.push(item.name);
    });
  }
  return extractedValue.join(", ");
};

export const fetchLabelsDropdownName = (values = []) => {
  const extractedValue = [];
  if (values && values.length) {
    values.forEach((item) => {
      extractedValue.push(item.label);
    });
  }
  return extractedValue.join(", ");
};

export const formatCurrency = (amount) => {
  return new Intl.NumberFormat("en-IN", {
    style: "currency",
    currency: "INR",
  }).format(amount);
};

export const formatDate = (date) => {
  return new Date(date).toLocaleDateString("en-CA");
};

export const removeErrorFieldsFromValues = (formData) => {
  const fields = {};
  for (const key in formData) {
    if (!key.includes("Error")) {
      fields[key] = formData[key];
    }
  }
  return fields;
};

export const fetchErrorFields = (formData, formModel) => {
  const fields = [];
  for (const key in formData) {
    if (key.includes("Error") && formData[key]) {
      formModel.forEach((form) => {
        if (form.field === key.replace("Error", "")) {
          fields.push(form.label);
        }
      });
    }
  }
  return fields;
};

export const downloadExportFile = (response, type, fileName) => {
  const url = window.URL.createObjectURL(new Blob([response.data || response]));
  const link = document.createElement("a");
  link.href = url;
  link.setAttribute("target", `_blank`);
  if (response.headers['content-disposition'].split("filename=")[1]) {
    link.setAttribute("download", response.headers['content-disposition'].split("filename=")[1].split('"')[1])
  }
  else {
    link.setAttribute("download", `${fileName || "Export"}.${type}`);
  }

  document.body.appendChild(link);
  link.click();
};

// export const hasPrivilege = key => {
//   if (token && token.privileges.includes(key)) {
//     return true;
//   } else {
//     return false;
//   }
// };

const convertArrayOfObjectsToCSV = (array) => {
  let result;

  const columnDelimiter = ",";
  const lineDelimiter = "\n";
  const keys = Object.keys(array[0]);

  result = "";
  result += keys.join(columnDelimiter);
  result += lineDelimiter;

  array.forEach((item) => {
    let ctr = 0;
    keys.forEach((key) => {
      if (ctr > 0) result += columnDelimiter;
      if (key === "skillSet") {
        item[key] = `"${item[key]}"`;
        // console.log(item[key]);
      }
      result += item[key];
      ctr++;
    });
    result += lineDelimiter;
  });

  return result;
};
export const downloadCSV = (array, name) => {
  const link = document.createElement("a");
  let csv = convertArrayOfObjectsToCSV(array);
  if (csv == null) return;

  const filename = `${name || "export"}.csv`;

  if (!csv.match(/^data:text\/csv/i)) {
    csv = `data:text/csv;charset=utf-8,${csv}`;
  }

  link.setAttribute("href", encodeURI(csv));
  link.setAttribute("download", filename);
  link.click();
};

export const handleAllFilter = (filters, multiFilterOperation, cic) => {
  let mandateFiltersValue = [];
  Object.keys(filters).forEach((key) => {
    let isEmpty = false;
    if (Array.isArray(filters[key].value)) {
      if (filters[key].value.length === 0) {
        isEmpty = true;
      }
    } else {
      if (filters[key].value === "") {
        isEmpty = true;
      }
    }
    if (!isEmpty) {
      if (mandateFiltersValue.length > 0) {
        mandateFiltersValue.push(multiFilterOperation);
      }
      if (Array.isArray(filters[key].value) && filters[key].value.length > 1) {
        let newArray = [];
        for (let i = 0; i < filters[key].value.length; i++) {
          if (newArray.length > 0) {
            newArray.push("OR");
          }
          newArray.push(key + ":cic:" + filters[key].value[i]);
        }
        mandateFiltersValue.push(newArray);
      } else {
        if (Array.isArray(filters[key].value)) {
          if (filters[key].value.length === 1) {
            mandateFiltersValue.push(
              key + ":" + cic + ":" + filters[key].value[0]
            );
          }
        } else {
          mandateFiltersValue.push(key + ":" + cic + ":" + filters[key].value);
        }
      }
    }
  });
  if (
    mandateFiltersValue.length === 1 &&
    Array.isArray(mandateFiltersValue[0])
  ) {
    mandateFiltersValue = mandateFiltersValue[0];
  }
  return mandateFiltersValue;
};

export const isEmpty = (filterValue) => {
  const array = [];
  filterValue &&
    Object.keys(filterValue).forEach((key) => {
      if (filterValue[key]) {
        array.push(filterValue[key]);
      }
    });
  return array && Object.keys(array).length;
};

export const getImages = (url, fullName) => {
  const token = fetchFromStorage(identifiers.token);
  try {
    axios({
      url: Config.BaseURL + url,
      method: "get",
      data: {},
      headers: { Authorization: `Bearer ${token.access_token}` },
      responseType: "blob",
    })
      .then((response) => {
        downloadExportFile(response, "xlsx", fullName);
      })
      .catch((err) => {
        alert("Oops! Something went wrong.");
      });
  } catch (err) { }
};

// export const askForApproval = (title, body, icon) => {
//   if (Notification.permission === 'granted') {
//     createNotification(title, body, icon);
//   } else {
//     Notification.requestPermission(permission => {
//       if (permission === 'granted') {
//         createNotification(title, body, icon);
//       }
//     });
//   }
// };

// const createNotification = (title, text, icon) => {
//   const noti = new Notification(title, {
//     body: text,
//     icon,
//   });
// };

import React, { Component, createRef } from "react";
import { connect } from "react-redux";
import * as $ from "jquery";
import { NavLink } from "react-router-dom";
import { TableComponent } from "../../../library/common/components";
import axiosInstance from "../../../core/Axios";
import { URLS } from "../../../library/common/constants";
import {
  fetchBonusOfferList,
  saveBonusOfferFilter
} from "../BonusOfferAction";
import {
  ActionMessage,
  Form,
  Loader,
  strings
} from "../../../library/common/components";
import DeleteRow from "../../../library/common/components/Delete";
import BonusOfferListJson from "./BonusOfferListJson";
import { BonusOfferFilterConstant } from "./Frame/BonusOfferListFilterConstant";
import {
  handleAllFilter,
  isEmpty,
  removeErrorFieldsFromValues
} from "../../../library/utilities";
import BonusOfferSearchFilterCriteria from "./Frame/BonusOfferSearchFilterCriteria";

import UploadCsv from "../../../library/common/components/UploadCsv/UploadCsv";
import DownloadCsv from "../../../library/common/components/DownloadCsv/DownloadCsv";


export class BonusOfferList extends Component {
  formRef = createRef();

  constructor(props) {
    super(props);
    this.__searchTimeout = null;
    this.state = {
      rowId: "",
      isLoading: false,
      isDeleted: null,
      initialRequest: {
        page: this.props.BonusOfferFilterCondition?.page,
        size: 10,
        filter: [],
        sort: [],
        graphql: null
      },
      multiFilterOperation: "AND",
      filters: {},
      messageBox: {
        display: false,
        type: "",
        message: ""
      }
    };
  }

  componentDidMount() {
    document.title = strings.BonusOffer_list_title;
    const {
      // fetchBonusOfferList,
      BonusOfferFilterCondition,
    } = this.props;
    const { initialRequest } = this.state;

    const isBonusOfferObjectIsEmpty = isEmpty(BonusOfferFilterCondition?.filterValue);
    isBonusOfferObjectIsEmpty > 0
      ? this.handleBonusOfferFilter(BonusOfferFilterCondition?.filterValue)
      : this.setState(
        {
          initialRequest: {
            ...initialRequest,
            page: BonusOfferFilterCondition?.page
          }
        },
        () => {
          // fetchBonusOfferList(initialRequest);
        }
      );
  }


  async componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevState.initialRequest !== this.state.initialRequest) {
      const { fetchBonusOfferList } = this.props;
      const { initialRequest } = this.state;
      this.setState({ isLoading: true });
      await fetchBonusOfferList(initialRequest);
      this.setState({ isLoading: false });
    }
  }

  EditItem = async id => {
    this.props.history.push(`/edit-BonusOffer/${id}`);
  };

  userToggle = index => {
    this.toggleUser(index);
  };

  deleteItem = (index, isDeleted) => {
    $(`#delete-modal`).modal("show");
    this.setState({ rowId: index, isDeleted: isDeleted });
  };


  updateState = (id, value, deletedFlag) => {
    if (value === "edit") {
      this.EditItem(id);
    } else if (value === "toggleUser") {
      this.userToggle(id);
    } else {
      this.deleteItem(id, deletedFlag);
    }
  };

  toggleUser = value => {
    this.setState({ isLoading: true });
    axiosInstance
      .put(URLS.toggleUsers(value))
      .then(({ status }) => {
        if (status === 200) {
          // this.setActionMessage(true, 'Success', 'User Successfully updated');
          // this.fetchInitialData();
          const { fetchBonusOfferList } = this.props;
          const { initialRequest } = this.state;
          fetchBonusOfferList(initialRequest);
          this.setState({ isLoading: false });
        }
      })
      .catch(err => {
        this.setState({ isLoading: false });
        if (err.hasOwnProperty("response")) {
          this.setActionMessage(true, "Error", err.response.data.message);
        } else {
          this.setActionMessage(true, "Error", "Oops! Something went wrong.");
        }
      });
  };

  setActionMessage = (display = false, type = "", message = "") => {
    let setActionMessage = {
      display: display,
      type: type,
      message: message
    };
    this.setState({ messageBox: setActionMessage });
  };
  handleFilterAndSorting = filteredValue => {
    this.setState({ initialRequest: filteredValue });
    const { BonusOfferFilterCondition, saveBonusOfferFilter } = this.props;
    saveBonusOfferFilter({ ...BonusOfferFilterCondition, page: filteredValue.page });
  };

  handleSearch = (value, name, finalInputData) => {
    const { filters } = this.state;
    const newFilters = { ...filters };
    newFilters[name] = {
      value,
      operation: "cic"
    };
    this.setState({ filters: newFilters }, () => {
      this.handleBonusOfferFilter(finalInputData);
    });
  };

  handleBonusOfferFilter = async finalInputData => {
    const { BonusOfferFilterCondition, saveBonusOfferFilter } = this.props;
    saveBonusOfferFilter({ ...BonusOfferFilterCondition, filterValue: finalInputData });
    this.setState(
      {
        filters: {
          brand: { value: finalInputData?.brand || "" },
          brandIdentifier: { value: finalInputData?.brandIdentifier || "" },
          clientPosCode: { value: finalInputData?.clientPosCode || "" },
          ean: { value: finalInputData?.ean || "" },
          // field:{value: finalInputData?.field || ""},
          loc: { value: finalInputData?.loc || "" },
          period: { value: finalInputData?.period || "" },
          segment: { value: finalInputData?.segment || "" },
          sku: { value: finalInputData?.sku || "" },
          skuIdentifier: { value: finalInputData?.skuIdentifier || "" }
        }
      },
      () => {
        this.handleBonusOfferUpdatedFilter();
      }
    );
  };

  handleBonusOfferUpdatedFilter = async () => {
    const { filters, initialRequest, multiFilterOperation } = this.state;
    if (filters) {
      const filteredValue = await handleAllFilter(
        filters,
        multiFilterOperation,
        "cic"
      );
      if (this.__searchTimeout) {
        clearTimeout(this.__searchTimeout);
      }
      this.__searchTimeout = setTimeout(() => {
        this.setState({
          initialRequest: { ...initialRequest, filter: filteredValue, page: 1 }
        });
      }, 500);
    }
  };

  handleFormChange = (field, value, inputData) => {
    let fieldValue = value;
    const fields = { field: fieldValue };
    let initialData = { ...inputData, ...fields };
    const finalInputData = removeErrorFieldsFromValues(initialData);
    this.handleSearch(fieldValue, field, finalInputData);
  };

  handleClearFilter = () => {
    const { BonusOfferFilterCondition, saveBonusOfferFilter } = this.props;
    this.setState(
      {
        initialRequest: {
          page: 1,
          size: 10,
          filter: [],
          sort: [],
          graphql: null
        },
        filters: {},
        //   brand: "",
        //   brandIdentifier: "",
        //   clientPosCode: "",
        //   ean: "",
        //   segment: "",
        //   loc: "",
        //   sku: "",
        //   skuIdentifier: "",
        //   period: ""
        // }
      },
      () => {
        saveBonusOfferFilter({
          ...BonusOfferFilterCondition,
          filterValue: this.state.filters,
          page: 1
        });
      }
    );
    this.formRef.resetForm();
  };

  updatedFilter = () => {
    const { initialRequest } = this.state;
    const newPage = initialRequest.page > 1 ? initialRequest.page - 1 : 1;
    this.setState({ initialRequest: { ...initialRequest, page: newPage } });
  };

  filterArray = () => {
    const { filters, multiFilterOperation } = this.state;
    let filteredValue = [];
    if (Object.keys(filters).length > 0) {
      filteredValue = handleAllFilter(
        filters,
        multiFilterOperation,
        "cic"
      );
    }
    return filteredValue
  };

  render() {
    const {
      rowId,
      isLoading,
      messageBox,
      initialRequest,
      isDeleted
    } = this.state;
    const {
      permission,
      BonusOfferList,
      fetchBonusOfferList,
      BonusOfferFilterCondition,
    } = this.props;
    return (
      <div className="main-content table-container">
        <div>
          {messageBox.display && (
            <ActionMessage
              message={messageBox.message}
              type={messageBox.type}
              styleClass={messageBox.styleClass}
            />
          )}
        </div>
        <div className="row mt-3">
          <div className="col-sm-12 table-wrapper">
            <div className="d-flex justify-content-between align-items-center topFilter">
              <div className="d-flex">
                <h3 style={{ color: "#F36633", fontWeight: "700" }}>{strings.BonusOffer_list_title}</h3>
                <button
                  title={strings.search_filter_text}
                  className="btn advanceSearch mt-0"
                  type="button"
                  data-toggle="collapse"
                  data-target="#collapseExample"
                  aria-expanded="false"
                  aria-controls="collapseExample"
                >
                  <i className="fa fa-filter" aria-hidden="true" />
                </button>
                <button
                  title={strings.clear_filter_text}
                  className="btn advanceSearch ml-2"
                  onClick={this.handleClearFilter}
                >
                  <i className="fa fa-refresh" aria-hidden="true" />
                </button>
                <UploadCsv
                  apiUrl={URLS.uploadBonusOfferBulk}
                  title="Upload Excel"
                />
                <DownloadCsv
                  apiUrl={URLS.bonusOffersListDownload}
                  title="Download Bonus Offers Excel"
                  //fileName="bonus-offers"
                  filters={() => this.filterArray()}
                />
              </div>
              {/* {permission.includes("ADD_USER") && (
                <NavLink
                  title={strings.BonusOffer_button_add}
                  className="btn btn-success font-weight-bold btn-main"
                  to="/add-BonusOffer"
                >
                  <i className="fa fa-plus" />
                </NavLink>
              )} */}
            </div>
            {/* <div className="selectedFilter">
              <div className="d-flex filterTextWrapper">
                <div className="badges">
                  <label>Page : </label>
                  {
                    <span className="filterBadge">
                      {initialRequest.page || 1}
                    </span>
                  }
                </div>
                {BonusOfferFilterCondition?.filterValue !== undefined && (
                  <BonusOfferSearchFilterCriteria
                    filterCondition={BonusOfferFilterCondition?.filterValue}
                  />
                )}
              </div>
            </div> */}
            <div
              className="collapse mb-1 mt-4 searchFilter"
              id="collapseExample"
            >
              <div className="card card-body">
                <span className="search_filter_text">
                  {strings.search_filter_text}
                </span>
                <div className="searchWrapper">
                  <Form
                    values={BonusOfferFilterCondition?.filterValue}
                    onChange={this.handleFormChange}
                    model={BonusOfferFilterConstant(strings, true)}
                    ref={el => (this.formRef = el)}
                  />
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-sm-12 table-wrapper">
                {isLoading && <Loader />}
                <TableComponent
                  formList={BonusOfferListJson(permission)}
                  initialSort="username"
                  filteredData={BonusOfferList}
                  permission={permission}
                  eventFunction={this.updateState}
                  users={BonusOfferList}
                  initialRequest={initialRequest}
                  handleFilterAndSorting={this.handleFilterAndSorting}
                />
              </div>
            </div>
            <DeleteRow
              updatedFilter={this.updatedFilter}
              totalSize={BonusOfferList?.numberOfElements}
              bodyText={isDeleted === false && strings.BonusOfferDeleteMessage}
              initialRequest={initialRequest}
              deleteUrl={URLS.deleteBonusOffer(rowId)}
              updatedList={fetchBonusOfferList}
            />
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ authReducer, BonusOffer }) => {
  return {
    permission: authReducer.token.privileges,
    BonusOfferList: BonusOffer.BonusOfferList,
    BonusOfferFilterCondition: BonusOffer.BonusOfferFilterCondition
  };
};
export default connect(mapStateToProps, {
  fetchBonusOfferList,
  saveBonusOfferFilter,
})(BonusOfferList);

import React from "react";
import PropTypes from "prop-types";

const Button = ({
  styleClass,
  disabled,
  testId,
  value,
  onClick,
  loading,
  type,
  icon,
  title,
  extraProps
}) => {
  return (
    <button
      title={title}
      onClick={onClick}
      disabled={disabled}
      data-test={testId}
      type={type}
      {...extraProps}
      className={`btn ${styleClass}`}
    >
      {!loading && value && (
        <span className={icon && "pr-3 font-weight-bold"}>{value}</span>
      )}
      {!loading && icon && <span className={`fa ${icon}`} />}
      {loading && <span className="fa fa-spinner fa-pulse" />}
    </button>
  );
};

export default Button;

Button.propTypes = {
  styleClass: PropTypes.string,
  extraProps: PropTypes.object,
  testId: PropTypes.string,
  value: PropTypes.string,
  type: PropTypes.string,
  icon: PropTypes.string,
  loading: PropTypes.bool,
  disabled: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
  title: PropTypes.string
};

Button.defaultProps = {
  type: "button",
  styleClass: "btn-success",
  testId: "",
  extraProps: {},
  title: "",
  value: "",
  icon: "",
  loading: false,
  disabled: false
};

import { storeConstants } from "../../library/common/constants";

const initialState = {
  EventHistoryList: [],
  userFilterCondition: {
    page: 1,
    filter: [],
    sort: [],
    graphql: null,
    size: 10,
    filterValue: {},
  },
};

const EventHistoryReducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case storeConstants.EVENT_HISTORYLIST:
      return {
        ...state,
        EventHistoryList: action.payload,
      };
    case storeConstants.PAGE_NUMBER_USER:
      return {
        ...state,
        userFilterCondition: action.payload,
      };
    default:
      return state;
  }
};

export default EventHistoryReducer;

import React, { useState } from "react";
import PropTypes from "prop-types";

import "./radio.scss";
import { validateInput } from "../../../utilities/Validators";
import { Radio, RadioGroup } from "react-radio-group";

const RadioButton = ({
  label,
  selected,
  onChange,
  validators,
  field,
  options,
  disabled
}) => {
  const [error, setError] = useState(null);
  const handleChange = event => {
    const inputError = validateInput(validators, event);
    setError(inputError);
    onChange(event, field, inputError);
  };
  return (
    <div className="customCheck radio">
      {label !== "" || label !== undefined ? <label>{label}</label> : null}
      <RadioGroup name={field} selectedValue={selected} onChange={handleChange}>
        {options &&
          options.length > 0 &&
          options.map((option, index) => (
            <label className="radio-container" key={index}>
              <Radio disabled={disabled} value={option.id} />
              <span className="label">{option.name}</span>
              <span className="checkmark" />
            </label>
          ))}
      </RadioGroup>
      {error && error.error && (
        <span className="text-danger">{error.message}</span>
      )}
    </div>
  );
};

RadioButton.defaultProps = {
  testId: "",
  validators: [],
  field: "",
  disabled: false
};

RadioButton.propTypes = {
  options: PropTypes.array,
  label: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  selected: PropTypes.any.isRequired,
  onChange: PropTypes.func.isRequired,
  validators: PropTypes.array,
  field: PropTypes.string
};

export default RadioButton;

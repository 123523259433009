import { storeConstants } from "../constants/index";
import { fetchFromStorage } from "../../utilities";

const lang = fetchFromStorage("language");
const defaultLang = { value: "en_IN", label: "English" };
const initialState = {
  selectedDefaultLanguage: lang || defaultLang,
};

const LanguageReducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case storeConstants.SET_LANGUAGE:
      return {
        ...state,
        selectedDefaultLanguage: action.payload
      };
    default:
      return state;
  }
};

export default LanguageReducer;

import React from "react";
import "./loginStyles.scss";
import { images } from "../../library/common/constants/ImageConstants";

const LeftSideImage = () => {
  return (
    <div className="col-sm-12 col-lg-6 login-background vh-100 p-0">
      <img src={images.loginBg} alt="background" />
    </div>
  );
};

export default LeftSideImage;

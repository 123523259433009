import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "./dateInputStyles.scss";
import { validateInput } from "../../../../library/utilities/Validators";
import "./dateInputStyles.scss";
import { fetchFromStorage } from "../../../utilities";
import strings from "../Translation/Translate";

const DateInput = ({
  label,
  field,
  value,
  validators,
  disabled,
  onChange,
  dateFormat,
  minDate,
  helpText,
  isHelp,
  extraProps,
  hasError,
  fieldError,
  placeholder,
  isClearable,
}) => {
  const [error, setError] = useState(null);
  const [localLanguage, setLocalLanguage] = useState("en");
  useEffect(() => {
    const language = fetchFromStorage("language");
    if (language) {
      const [lang] = language.value.split("_");
      setLocalLanguage(lang);
    }
  }, [localLanguage]);
  const handleChange = (date) => {
    const inputError = validateInput(validators, date);
    setError(inputError);
    onChange(date, field, inputError);
  };

  return (
    <div className="date-pricker-wrapper input-field">
      {label !== "" ? (
        <label className="inputField-label" htmlFor="inputField">
          {label}
          {isHelp && (
            <span
              style={{ display: "none" }}
              className="help"
              data-toggle="tooltip"
              data-placement="top"
              title={helpText}
            >
              <i className="ml-2 fa fa-info-circle" />
            </span>
          )}
        </label>
      ) : null}

      <DatePicker
        autoComplete="off"
        showYearDropdown
        showMonthDropdown
        dateFormat={dateFormat}
        disabled={disabled}
        isClearable={isClearable}
        locale={localLanguage}
        minDate={minDate}
        onChange={handleChange}
        placeholderText={placeholder}
        selected={value}
        {...extraProps}
      />
      {/* <DatePicker
        locale={localLanguage}
        disabled={disabled}
        value={value}
        onChange={handleChange}
        format={format}
        minDate={minDate}
        {...extraProps}
      /> */}
      {error && error.error && (
        <span className="text-danger">{error.message}</span>
      )}
      {!error && hasError && fieldError && (
        <span className="text-danger">{strings.requiredText}</span>
      )}
    </div>
  );
};

DateInput.propTypes = {
  label: PropTypes.string,
  title: PropTypes.string,
  textHint: PropTypes.string,
  field: PropTypes.string,
  value: PropTypes.instanceOf(Date),
  validators: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
  format: PropTypes.string,
  minDate: PropTypes.oneOfType([PropTypes.instanceOf(Date), PropTypes.string]),
  extraProps: PropTypes.object,
  disabled: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  handleOnClick: PropTypes.func,
  isHelp: PropTypes.bool,
  helpText: PropTypes.string,
  hasError: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  fieldError: PropTypes.bool,
  placeholder: PropTypes.string,
  isClearable: PropTypes.bool,
};

DateInput.defaultProps = {
  label: "",
  title: "",
  field: "",
  textHint: "",
  value: null,
  validators: "",
  disabled: false,
  hasError: false,
  handleOnClick: () => {},
  fieldError: false,
  minDate: null,
  extraProps: {},
  format: "y-MM-dd",
  helpText: "",
  isHelp: true,
  placeholder: "",
  isClearable: false,
};

export default DateInput;

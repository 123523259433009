import axiosInstance from "../../core/Axios";
import { storeConstants } from "../../library/common/constants";
import { URLS } from "../../library/common/constants/UrlConstants";


export const fetchBonusOfferList = userPostData => dispatch => {
  return axiosInstance
    .post(URLS.getBonusOfferPage, userPostData)
    .then(({ status, data }) => {
      if (status === 200) {
        dispatch({
          type: storeConstants.FETCH_BONUS_OFFERLIST,
          payload: data
        });
      }
    })
    .catch(() => {
      dispatch({
        type: storeConstants.FETCH_BONUS_OFFERLIST,
        payload: []
      });
    });
};



export const saveBonusOfferFilter = pageNumber => dispatch => {
  dispatch({
    type: storeConstants.PAGE_NUMBER_USER,
    payload: pageNumber
  });
};

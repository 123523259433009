import React, { Component } from "react";
import PropTypes from "prop-types";
import { cloneDeep } from "lodash";

import {
  Checkbox,
  DateInput,
  Dropdown,
  InputField,
  RadioButton
} from "../index";
import Autosuggests from "../suggetion";
import Section from "../Section";
import DropdownAddAutocomplete from "../DropdownAddAutocomplete";
import CreateDropdown from "../CreateDropdown";

export default class Form extends Component {
  state = {
    formData: {},
    isFormValid: false
  };

  componentDidMount() {
    this.prepareFormData();
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.strings !== prevProps.strings ||
      this.props?.isFormUpdated !== prevProps.isFormUpdated ||
      (Object.keys(this.props.values).length > 0 &&
        this.props.values !== prevProps.values)
    ) {
      this.prepareFormData();
    }
  }

  handleChange = (value, field, error, deleted) => {
    const formData = cloneDeep(this.state.formData);
    formData[field] = value;
    if (deleted?.deletedField === field) {
      formData[field + "deleted"] = deleted?.DeletedFile;
    }
    formData[field + "Error"] = error && error.error;
    this.setState({
      formData,
      isFormValid: this.validateForm(formData)
    });
    if (this.props.onChange) {
      this.props.onChange(field, value, formData);
    }
  };

  validateForm = formData => {
    const { model } = this.props;
    let isFormValid = true;
    model.forEach(item => {
      if (item.required || formData[item.field + "Error"]) {
        isFormValid = isFormValid && !formData[item.field + "Error"];
      }
    });
    return isFormValid;
  };

  getFormData = () => {
    const { formData, isFormValid } = this.state;
    return { formData, isFormValid };
  };

  resetForm = () => {
    this.prepareFormData();
  };

  prepareFormData() {
    const { model, values } = this.props;
    const formData = {};
    if (Object.keys(values).length !== 0) {
      model.forEach(item => {
        formData[item.field] =
          values &&
          (values[item.field] || values[item.field] === 0) &&
          values[item.field] !== ""
            ? values[item.field]
            : "";
        if (formData[item.field] || !item.required) {
          formData[item.field + "Error"] =
            values &&
            values[item.field] &&
            values[item.field !== ""] &&
            item.required
              ? true
              : false;
        } else {
          formData[item.field + "Error"] =
            values &&
            values[item.field] &&
            values[item.field !== ""] &&
            item.required
              ? false
              : true;
        }
      });
      this.setState({ formData, isFormValid: this.validateForm(formData) });
    } else {
      model.forEach(item => {
        formData[item.field] =
          values && values[item.field] ? values[item.field] : "";
        formData[item.field + "Error"] = item.required;
      });

      this.setState({ formData, isFormValid: this.validateForm(formData) });
    }
  }

  renderFormFields() {
    const { model, dropdownData } = this.props;
    const { formData } = this.state;
    const arrayOfFields = [];
    model.forEach((item, key) => {
      switch (item.type) {
        case "text":
        case "email":
        case "number":
        case "password":
        case "textarea":
        case "time":
          arrayOfFields.push(
            <div key={key} className={"form-group " + item.styleClass}>
              <InputField
                hasError={this.props.hasError || ""}
                field={item.field}
                inputValue={
                  formData[item.field] || formData[item.field] === 0
                    ? formData[item.field]
                    : ""
                }
                type={item.type}
                label={item.label}
                fieldError={formData[item.field + "Error"]}
                testId={item.testId}
                placeholder={item.placeholder}
                validators={item.validators}
                onChange={this.handleChange}
                helpText={item.helpText}
                isPassword={item.isPassword}
                isHelp={item.isHelp}
                isDisabled={item.isDisabled}
                extraProps={item.extraProps}
              />
            </div>
          );
          break;

        case "dropdown":
          arrayOfFields.push(
            <div key={key} className={"form-group " + item.styleClass}>
              <Dropdown
                fieldError={formData[item.field + "Error"]}
                hasError={this.props.hasError || false}
                onChange={this.handleChange}
                options={dropdownData && dropdownData[item.field]}
                label={item.label}
                testId={item.testId}
                placeholder={item.placeholder}
                validators={item.validators}
                field={item.field}
                value={formData[item.field] ? formData[item.field] : ""}
                isMulti={item.isMulti}
                isSearchable={item.isSearchable}
                isDisabled={item.isDisabled}
                closeMenuOnSelect={item.closeMenuOnSelect}
                helpText={item.helpText}
                isHelp={item.isHelp}
                textHint={item.textHint}
                noOptionsMessage={item.noOptionsMessage}
                extraProps={item.extraProps}
                isClearable={item.isClearable}
              />
            </div>
          );
          break;
        case "createDropdown":
          arrayOfFields.push(
            <div key={key} className={"form-group " + item.styleClass}>
              <CreateDropdown
                fieldError={formData[item.field + "Error"]}
                hasError={this.props.hasError || false}
                onChange={this.handleChange}
                options={dropdownData && dropdownData[item.field]}
                label={item.label}
                testId={item.testId}
                placeholder={item.placeholder}
                validators={item.validators}
                field={item.field}
                value={formData[item.field] ? formData[item.field] : ""}
                isMulti={item.isMulti}
                isSearchable={item.isSearchable}
                isDisabled={item.isDisabled}
                closeMenuOnSelect={item.closeMenuOnSelect}
                helpText={item.helpText}
                isHelp={item.isHelp}
                textHint={item.textHint}
                noOptionsMessage={item.noOptionsMessage}
                extraProps={item.extraProps}
                isClearable={item.isClearable}
              />
            </div>
          );
          break;
      
        case "addAutocomplete":
          arrayOfFields.push(
            <div key={key} className={"form-group " + item.styleClass}>
              <DropdownAddAutocomplete
                fieldError={formData[item.field + "Error"]}
                hasError={this.props.hasError || ""}
                onChange={this.handleChange}
                handleGetValue={item.handleGetValue}
                label={item.label}
                testId={item.testId}
                placeholder={item.placeholder}
                validators={item.validators}
                field={item.field}
                value={formData[item.field] ? formData[item.field] : null}
                isSearchable={item.isSearchable}
                helpText={item.helpText}
                isMulti={item.isMulti}
                isHelp={item.isHelp}
                extraProps={item.extraProps}
                textHint={item.textHint}
              />
            </div>
          );
          break;
        case "Autosuggests":
          arrayOfFields.push(
            <div key={key} className={"form-group " + item.styleClass}>
              <Autosuggests
                fieldError={formData[item.field + "Error"]}
                hasError={this.props.hasError || false}
                onChangeMethod={this.handleChange}
                handleOnChanges={item.handleOnChanges}
                label={item.label}
                testId={item.testId}
                placeholder={item.placeholder}
                validators={item.validators}
                field={item.field}
                suggestionsValue={item.suggestionsValue}
                value={formData[item.field] ? formData[item.field] : ""}
                disabled={item.disabled}
              />
            </div>
          );
          break;
        case "date":
          arrayOfFields.push(
            <div key={key} className={"form-group " + item.styleClass}>
              <DateInput
                hasError={this.props.hasError || ""}
                label={item.label}
                testId={item.testId}
                validators={item.validators}
                dateFormat={item.dateFormat}
                field={item.field}
                value={formData[item.field] ? formData[item.field] : null}
                fieldError={formData[item.field + "Error"]}
                disabled={item.disabled}
                onChange={this.handleChange}
                helpText={item.helpText}
                isHelp={item.isHelp}
                minDate={item.minDate}
                textHint={item.textHint}
                title={item.title}
                handleOnClick={item.handleOnClick}
                extraProps={item.extraProps}
                placeholder={item.placeholder}
                isClearable={item.isClearable}
              />
            </div>
          );
          break;
        case "checkbox":
          arrayOfFields.push(
            <div key={key} className={"form-group " + item.styleClass}>
              <Checkbox
                selected={formData[item.field] ? formData[item.field] : false}
                label={item.label}
                testId={item.testId}
                validators={item.validators}
                field={item.field}
                checked={formData[item.field]}
                disabled={item.disabled}
                onChange={this.handleChange}
                helpText={item.helpText}
                isHelp={item.isHelp}
                extraProps={item.extraProps}
              />
            </div>
          );
          break;
        case "radio":
          arrayOfFields.push(
            <div key={key} className={"form-group " + item.styleClass}>
              <RadioButton
                selected={formData[item.field] ? formData[item.field] : false}
                fieldError={formData[item.field + "Error"]}
                label={item.label}
                options={item.options}
                validators={item.validators}
                field={item.field}
                disabled={item.disabled}
                onChange={this.handleChange}
                helpText={item.helpText}
                isHelp={item.isHelp}
              />
            </div>
          );
          break;
        case "section":
          arrayOfFields.push(
            <div key={key} className={"form-group " + item.styleClass}>
              <Section label={item.label} />
            </div>
          );
          break;
        default:
          break;
      }
    });

    return arrayOfFields;
  }

  render() {
    return (
      <div className="form-container" data-test={this.props.testId}>
        <div className="row">{this.renderFormFields()}</div>
      </div>
    );
  }
}

Form.defaultProps = {
  values: {},
  testId: "",
  dropdownData: {},
  onChange: () => {}
};

Form.propTypes = {
  model: PropTypes.array.isRequired,
  values: PropTypes.object,
  testId: PropTypes.string,
  onChange: PropTypes.func,
  dropdownData: PropTypes.object
};

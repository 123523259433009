import React, { Component, lazy, Suspense } from "react";
import { connect } from "react-redux";

import { logoutUser, toggleSidebar } from "../../library/common/actions";
import { Loader } from "../../library/common/components";

const DefaultSideNav = lazy(() => import("./DefaultSideNav"));
const DefaultHeader = lazy(() => import("./DefaultHeader"));

export class DefaultLayout extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeItem: ""
    };
  }

  componentDidMount() {
    const url = `${this.props.location.pathname}`;
    const selected = url.slice(1);
    this.setState({ activeItem: selected });
  }

  componentDidUpdate(prevProps) {
    if (prevProps.location.pathname !== this.props.location.pathname) {
      const url = `${this.props.location.pathname}`;
      const selected = url.slice(1);
      this.setState({ activeItem: selected });
    }
  }

  handleLogout = () => {
    this.props.logoutUser();
  };

  render() {
    const {
      user,
      isToggle,
      toggleSidebar,
    } = this.props;
    const { activeItem } = this.state;
    return (
      <div>
        <Suspense fallback={<Loader />}>
          <DefaultHeader
            propsValue={this.props}
            toggleSidebar={toggleSidebar}
            isToggle={isToggle}
            onLogout={this.handleLogout}
            user={user}
          />
        </Suspense>

        <div className={`container-fluid ${isToggle && "main-wrapper"}`}>
          <div className="row no-gutter" style={{ paddingTop: "80px" }}>
            <div
              className={`pr-0 pl-0 sidebarWidth ${!isToggle &&
                "col-lg-3 col-xl-2"} `}
            >
              <Suspense fallback={<Loader />}>
                <DefaultSideNav
                  toggleSidebar={toggleSidebar}
                  isToggle={isToggle}
                  permission={user}
                  propsValue={this.props}
                  locationPath={activeItem}
                />
              </Suspense>
            </div>
            <div
              className={`main-container ${
                isToggle ? "col-sm-12" : "col-lg-9 col-xl-10"
              }`}
            >
              <div>
                <Suspense fallback={<Loader />}>{this.props.children}</Suspense>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ authReducer }) => {
  return {
    user: authReducer.token,
    isToggle: authReducer.isToggle,
  };
};

export default connect(mapStateToProps, {
  logoutUser,
  toggleSidebar
})(DefaultLayout);

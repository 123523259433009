import React from "react";
import * as $ from "jquery";
import { Button } from "../../../../library/common/components";
import strings from "../Translation/Translate";
import { useHistory } from "react-router-dom";
import PropTypes from "prop-types";
import { ActionMessage } from "../index";

const DefaultModal = ({ navigate, title, message }) => {
  const history = useHistory();
  const closeModal = () => {
    $("#common-modal").modal("hide");
    history.push(`/${navigate}`);
  };

  return (
    <div
      data-backdrop="static"
      className="modal fade deleteModal"
      id="common-modal"
      tabIndex="-1"
      role="dialog"
      aria-labelledby="exampleModalCenterTitle"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-dialog-centered" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="exampleModalCenterTitle">
              {title}
            </h5>
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
              onClick={closeModal}
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
            <ActionMessage
              message={message}
              type="Success"
              styleClass={message.styleClass}
            />
          </div>
          <div className="text-center modal-footer justify-content-center">
            <Button
              value={strings.common_ok_title}
              icon="fa-times-circle"
              styleClass="btn-success mr-3"
              onClick={closeModal}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
DefaultModal.propTypes = {
  title: PropTypes.string,
  message: PropTypes.string,
  navigate: PropTypes.string
};
DefaultModal.defaultProps = {
  title: "",
  message: "",
  navigate: ""
};
export default DefaultModal;

import React from "react";
import { Redirect, Route, withRouter } from "react-router-dom";

import DefaultLayout from "../DefaultLayout";

const PrivateRoute = withRouter(
  ({ isLoggedIn, component: Component, ...rest }) => {
    const componentCheck = props => {
      if (isLoggedIn) {
        return (
          <DefaultLayout {...props}>
            <Component {...props} />
          </DefaultLayout>
        );
      } else {
        return (
          <Redirect
            to={{
              pathname: "/",
              state: { from: props.location }
            }}
          />
        );
      }
    };

    return <Route {...rest} render={props => componentCheck(props)} />;
  }
);

export default PrivateRoute;

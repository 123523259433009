import React, { useState } from "react";
import PropTypes from "prop-types";
import strings from "../Translation/Translate";
import AsyncCreatableSelect from "react-select/async-creatable";
import { validateInput } from "../../../utilities/Validators";

const DropdownAddAutocomplete = ({
  value,
  field,
  label,
  testId,
  isMulti,
  validators,
  placeholder,
  isSearchable,
  onChange,
  helpText,
  isHelp,
  textHint,
  fieldError,
  hasError,
  handleGetValue,
  extraProps
}) => {
  const [error, setError] = useState(null);
  const [inputValue, setValue] = useState("");
  const [selectedValue, setSelectedValue] = useState(null);

  const handleInputChange = async value => {
    setValue(value);
  };
  const handleChange = value => {
    setSelectedValue(value);
    const inputError = validateInput(validators, value);
    setError(inputError);
    if (onChange) {
      onChange(value, field, inputError);
    }
  };
  return (
    <div className="app-dropdown">
      {label !== "" ? (
        <label className="inputField-label" htmlFor="inputField">
          {label}
          {textHint && (
            <span className="small fw500 text-info">{textHint}</span>
          )}
          {isHelp && (
            <span
              style={{ display: "none" }}
              className="help"
              data-toggle="tooltip"
              data-placement="top"
              title={helpText}
            >
              <i className="ml-2 fa fa-info-circle" />
            </span>
          )}
        </label>
      ) : null}
      <AsyncCreatableSelect
        testId={testId}
        placeholder={placeholder}
        field={field}
        cacheOptions={true}
        isSearchable={isSearchable}
        isClearable={true}
        defaultOptions
        isMulti={isMulti}
        value={value}
        getOptionLabel={e => e.label}
        getOptionValue={e => e.value}
        loadOptions={handleGetValue}
        onInputChange={handleInputChange}
        onChange={handleChange}
        {...extraProps}
      />
      {error && error.error && (
        <span className="text-danger">{error.message}</span>
      )}
      {!error && hasError && fieldError && (
        <span className="text-danger">{strings.requiredText}</span>
      )}
    </div>
  );
};

export default DropdownAddAutocomplete;

DropdownAddAutocomplete.propTypes = {
  options: PropTypes.array,
  placeholder: PropTypes.string,
  isSearchable: PropTypes.bool,
  isMulti: PropTypes.bool,
  validators: PropTypes.array,
  value: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  testId: PropTypes.string,
  field: PropTypes.string,
  label: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  helpText: PropTypes.string,
  textHint: PropTypes.string,
  isHelp: PropTypes.bool,
  extraProps: PropTypes.object
};

DropdownAddAutocomplete.defaultProps = {
  placeholder: "Please select",
  isSearchable: true,
  isDisabled: false,
  isMulti: false,
  validators: [],
  testId: "",
  field: "",
  value: {},
  label: "",
  closeMenuOnSelect: true,
  helpText: "",
  isHelp: true,
  textHint: "",
  extraProps: {}
};

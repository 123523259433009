export const EventHistoryFilterConstant = (strings) => {
  return [
    {
      placeholder: `${strings.event_history_eventType}`,
      value: "",
      type: "text",
      field: "eventType",
      validators: [],
      required: false,
      styleClass: "col-sm-2",
      extraProps: { maxLength: 20 },
    },
  ];
};


import addIcon from '../../../resources/images/addIcon.svg';
import deleteIcon from '../../../resources/images/deleteIcon.svg';
import editIcon from '../../../resources/images/editIcon.svg';
import detailIcon from '../../../resources/images/detailIcon.svg';
import copyIcon from '../../../resources/images/copyIcon.svg';
import activeToggleIcon from '../../../resources/images/activeToggleIcon.svg';
import inActiveToggleIcon from '../../../resources/images/inActiveToggleIcon.svg';
import privilegeIcon from '../../../resources/images/privilegeIcon.svg';
import resetPasswordIcon from '../../../resources/images/resetPasswordIcon.svg';
import closeIcon from '../../../resources/images/closeIcon.svg';
import logo from '../../../resources/images/logo.png';



export const images = {
  addIcon,
  deleteIcon,
  editIcon,
  detailIcon,
  copyIcon,
  activeToggleIcon,
  inActiveToggleIcon,
  privilegeIcon,
  resetPasswordIcon,
  closeIcon,
  logo,
};

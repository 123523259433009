import axiosInstance from "../../core/Axios";
import { storeConstants } from "../../library/common/constants";
import { URLS } from "../../library/common/constants/UrlConstants";

export const fetchEventConfigurationList = (userPostData) => (dispatch) => {
  return axiosInstance
    .post(URLS.getEventConfigurationPage, userPostData)
    .then(({ status, data }) => {
      if (status === 200) {
        dispatch({
          type: storeConstants.FETCH_EVENTCONFIGLIST,
          payload: data,
        });
      }
    })
    .catch(() => {
      dispatch({
        type: storeConstants.FETCH_EVENTCONFIGLIST,
        payload: [],
      });
    });
};

export const saveEventConfigurationFilter = (pageNumber) => (dispatch) => {
  dispatch({
    type: storeConstants.PAGE_NUMBER_USER,
    payload: pageNumber,
  });
};

export const ManualOfferFilterConstant = (strings) => {
  return [
    {
      placeholder: `${strings.ManualOffer_text_loc}`,
      value: "",
      type: "text",
      field: "loc",
      validators: [],
      required: false,
      styleClass: "col-sm-2",
      extraProps: { maxLength: 20 }
    },
    {
      placeholder: `${strings.ManualOffer_text_period}`,
      value: "",
      type: "text",
      field: "period",
      validators: [],
      required: false,
      styleClass: "col-sm-2",
      extraProps: { maxLength: 20 }
    },
    {
      placeholder: `${strings.ManualOffer_text_client_code}`,
      value: "",
      type: "text",
      field: "clientPosCode",
      validators: [],
      required: false,
      styleClass: "col-sm-2",
      extraProps: { maxLength: 50 }
    },
    {
      placeholder: `${strings.ManualOffer_text_ean}`,
      value: "",
      type: "text",
      field: "ean",
      extraProps: { maxLength: 15 },
      validators: [],
      required: false,
      styleClass: "col-sm-2"
    },
    {
      placeholder: `${strings.ManualOffer_text_brand_hash}`,
      value: "",
      type: "text",
      field: "brandIdentifier",
      extraProps: { maxLength: 15 },
      validators: [],
      required: false,
      styleClass: "col-sm-2"
    },
    {
      placeholder: `${strings.ManualOffer_text_brand}`,
      value: "",
      type: "text",
      field: "brand",
      extraProps: { maxLength: 15 },
      validators: [],
      required: false,
      styleClass: "col-sm-2"
    },
    {
      placeholder: `${strings.ManualOffer_text_sku_hash}`,
      value: "",
      type: "text",
      field: "skuIdentifier",
      extraProps: { maxLength: 15 },
      validators: [],
      required: false,
      styleClass: "col-sm-2 mt-2"
    },
    {
      placeholder: `${strings.ManualOffer_text_sku}`,
      value: "",
      type: "text",
      field: "sku",
      extraProps: { maxLength: 15 },
      validators: [],
      required: false,
      styleClass: "col-sm-2 mt-2"
    },
    {
      placeholder: `${strings.ManualOffer_text_segment}`,
      value: "",
      type: "text",
      field: "segment",
      extraProps: { maxLength: 15 },
      validators: [],
      required: false,
      styleClass: "col-sm-2 mt-2"
    },
  ];
};

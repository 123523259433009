import * as $ from 'jquery';
import React, { useEffect, useState } from 'react';
import swal from 'sweetalert';
import axiosInstance from '../../../../core/Axios';
import AsideModal from '../AsideModal';
import MainLoader from '../MainLoader'
import './export.scss';
import DataUpload from '../DataUpload/DataUpload';

const UploadCsv = ({ apiUrl, title }) => {
  const [loading, setIsLoading] = useState(false);
  const [deletedFile, setDeletedFile] = useState('');
  const [upload, setUpload] = useState([]);
  const [uploadErrorLink, setUploadErrorLink] = useState('');
  // const [uploadedFileHistory, setUploadedFileHistory] = useState([]);
  const [clearImages, setClearImages] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  // const [counter, setCounter] = useState(0);
  useEffect(() => {
    setIsOpen(true);
    setUpload([]);
  }, [isOpen]);

  const handleDeleted = deletedFile => {
    setDeletedFile(deletedFile);
  };

  const handleUpload = candidateFile => {
    setUpload(candidateFile);
  };

  let userAgentString = navigator.userAgent;
  const isFirefox = userAgentString.indexOf("Firefox") > -1

  const isCloseModal = async () => {
    // setCounter(0);
    $('#uploadSvg').modal('hide');
    setIsOpen(false);
  };

  const openModal = value => {
    setUploadErrorLink('');
    setIsOpen(true);
    $('#uploadSvg').modal('show');
  };


  const handleUploadFile = async () => {
    setClearImages(false);
    if (upload && upload.length !== 0) {
      try {
        setIsLoading(true);
        const body = new FormData();
        for (let i = 0; i < upload.length; i++) {
          body.append('list', upload[i]);
        }
        if (upload.length === 0) {
          body.append('list', null);
        }
        const config = {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        };
        const deletedFilesList = [...deletedFile];
        body.append('deletedFiles', deletedFilesList ? deletedFilesList.join(',') : '');
        const { status, data } = await axiosInstance.post(apiUrl, body, config);
        if (status === 200 || status === 201) {
          // setUploadedFileHistory([data]);
          setUpload([]);
          setDeletedFile('');
          setIsLoading(false);
          setClearImages(true);
          if (data.errorFile === null) {
            swal('', 'File Uploaded successfully', 'success');
          } else {
            if (isFirefox)
              setUploadErrorLink(encodeURIComponent(atob(data?.errorFile)))
            else
              setUploadErrorLink(data?.errorFile)
            swal('', 'Error while uploading file', 'error');
          }

          // isCloseModal();
        }
      } catch (e) {
        setIsLoading(false);
        if (e.response) {
          swal('', e?.response?.data?.message, 'error');
        }
      }
    } else {
      swal('', 'Please Upload File', 'error');
    }
  };



  const uploadFileForm = () => {
    return (
      <div className="downloadModal">
        <div className="col-sm-12">
          <DataUpload
            uploadAllowed
            uploadSubText=""
            category="OFFER_LETTER"
            deletedImage={handleDeleted}
            handleValue={handleUpload}
            label="Upload"
            media=""
            clearImages={clearImages}
            accept=".csv, application/vnd.ms-excel, text/csv"
          // maxSize={5300000}
          />
          {uploadErrorLink &&
            <div>
              Please check {` `}
               <a
                className='text-decoration'
                target="_blank"
                download="errors.xlsx"
                href={`data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64;charset=utf-8,${uploadErrorLink}`}
                rel="noreferrer">
                Error File
              </a> 
              {/*<a
                className='text-decoration'
                target="_blank"
                download="errors.csv"
                href={`data:text/csv;base64;charset=utf-8,${uploadErrorLink}`}
                rel="noreferrer">
                Error File
              </a>*/}

              {` `} for details
            </div>
          }
        </div>
      </div>
    );
  };

  return (
    <div className="exportFile d-inline">
      {loading && <MainLoader />}
      <button
        data-toggle="tooltip"
        title={title}
        className="btn advanceSearch ml-2"
        onClick={() => openModal('upload')}>
        <i className="fa fa-upload" aria-hidden="true" />
      </button>


      {isOpen && (
        <AsideModal
          label="Upload"
          classes="col-sm-12 mt-3 text-left mt-4"
          modalClass=""
          modalTitle={title}
          id="uploadSvg"
          handleFormData={handleUploadFile}
          isCloseModal={isCloseModal}
          form={uploadFileForm}
        />
      )}
    </div>
  );
};
export default UploadCsv;

import styled from "styled-components";

export const MainWrapper = styled.div`
  // header
//   .header-fixed {

//     z-index: 1001;
//     background-color: ${props =>
  props.theme.header.backgroundColor} !important;
//     border: solid 1px ${props => props.theme.header.borderColor};
//     color: ${props => props.theme.header.textColor};
//     padding:0 1rem;
//     .navbar-brand {
//     //padding:0.8rem 1rem;
//       color: ${props => props.theme.header.headerLogoColor};
//     }
//   }

//   // sidemenu
  
//   .custom-sidebar {
//     background-color: ${props => props.theme.sidemenu.backgroundColor};
    
//     .item {
//       color: ${props => props.theme.sidemenu.textColor} !important;
//       &.active {
//           color: ${props => props.theme.sidemenu.activeTextColor} !important;
//           background: ${props => props.theme.sidemenu.activebackgroundColor};
         
//       }
//             //  &:hover {
//             //   color: ${props =>
  props.theme.sidemenu.hoverTextColor} !important;
//             //   background: ${props =>
  props.theme.sidemenu.hoverBackgroundColor};
//             // }
//     }
//   }
//   // main content
  
//   .table-container div[class$=TableHeadRow] {
//     background-color:${props =>
  props.theme.content.tableList.headerbackgroundColor};
// }
// .table-container div[class$='TableCol_Sortable']{
//    color: ${props => props.theme.content.tableList.headertextColor};
// }
//   .table {
//     thead {
//       th {
//       border-bottom:0;
//         background-color: ${props =>
  props.theme.content.tableList.headerbackgroundColor};
//         color: ${props => props.theme.content.tableList.headertextColor};
//       }
//     }
//     td {
//       color: ${props => props.theme.content.tableList.trTextColor};
//     }
//   }
`;

export const TextField = styled.input`
  height: 38px;
  width: 200px;
  border-radius: 3px;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  padding: 0 32px 0 16px;
  border: 1px solid #ddd;

  &:hover {
    cursor: pointer;
  }
`;

export const ClearButton = styled.button`
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  border: 1px solid #ddd;
  border-left: 0;
  height: 38px;
  width: 36px;
  text-align: center;
`;

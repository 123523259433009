import React from "react";
import memoize from "memoize-one";
import { strings } from "../../../library/common/components";
import { images } from "../../../library/common/constants/ImageConstants";

const EventConfigurationListJson = (permission) =>
  memoize((handleAction) => [
    {
      name: strings.event_config_loc,
      sortable: true,
      sort: "loc",
      selector: (row) => row?.loc,
    },
    {
      name: strings.event_config_language_code,
      sortable: true,
      sort: "language",
      selector: (row) => row?.language,
    },

    {
      name: strings.event_config_event,
      sortable: true,
      sort: "eventType",
      selector: (row) => row?.eventType,
    },
    {
      name: strings.event_config_content_type,
      sortable: true,
      sort: "contentType",
      selector: (row) => row?.contentType,
    },
    {
      name: strings.event_config_email,
      sortable: true,
      sort: "emailNotification",
      selector: (row) => (row?.emailNotification ? "Yes" : "No"),
    },
    {
      name: strings.event_config_email_frequency,
      sortable: true,
      sort: "emailFrequency",
      selector: (row) => row?.emailFrequency,
    },
    {
      name: strings.event_config_email_frequency_period,
      sortable: true,
      sort: "emailFrequencyPeriod",
      selector: (row) => row?.emailFrequencyPeriod,
    },
    {
      name: strings.event_config_email_max_notification_count,
      sortable: true,
      sort: "emailMaxNotificationCount",
      selector: (row) => row?.emailMaxNotificationCount,
    },
    {
      name: strings.event_config_email_template_name,
      sortable: true,
      sort: "emailTemplate",
      selector: (row) => row?.emailTemplate,
    },
    {
      name: strings.event_config_waba,
      sortable: true,
      sort: "waBaNotification",
      selector: (row) => (row?.waBaNotification ? "Yes" : "No"),
    },
    {
      name: strings.event_config_waba_frequency,
      sortable: true,
      sort: "waBaFrequency",
      selector: (row) => row?.waBaFrequency,
    },
    {
      name: strings.event_config_waba_frequency_period,
      sortable: true,
      sort: "waBaFrequencyPeriod",
      selector: (row) => row?.waBaFrequencyPeriod,
    },
    {
      name: strings.event_config_waba_max_notification_count,
      sortable: true,
      sort: "waBaMaxNotificationCount",
      selector: (row) => row?.waBaMaxNotificationCount,
    },
    {
      name: strings.event_config_waba_template_name,
      sortable: true,
      sort: "waBaTemplate",
      selector: (row) => row?.waBaTemplate,
    },
    {
      name: strings.event_config_news_board,
      sortable: true,
      sort: "newsBoardNotification",
      selector: (row) => (row?.newsBoardNotification ? "Yes" : "No"),
    },
    {
      name: strings.event_config_news_board_notification_display_count,
      sortable: true,
      sort: "newsBoardNotificationDisplayCount",
      selector: (row) => row?.newsBoardNotificationDisplayCount,
    },
    {
      name: strings.event_config_threshold_type,
      sortable: true,
      sort: "thresholdType",
      selector: (row) => row?.thresholdType,
    },
    {
      name: strings.event_config_threshold_value,
      sortable: true,
      sort: "thresholdValue",
      selector: (row) => row?.thresholdValue,
    },
  ]);

export default EventConfigurationListJson;

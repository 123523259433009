import React, { useState } from "react";
import PropTypes from "prop-types";
import * as $ from "jquery";

import { validateInput } from "../../../../library/utilities/Validators";
import strings from "../Translation/Translate";

const InputField = ({
  type,
  hasError,
  validators,
  onChange,
  label,
  field,
  testId,
  inputValue,
  placeholder,
  extraProps,
  helpText,
  isHelp,
  fieldError,
  isDisabled,
  isPassword,
}) => {
  const [error, setError] = useState(null);
  const [pass, setPass] = useState(false);
  const handleChange = (event) => {
    const { value } = event.target;
    const inputError = validateInput(validators, value);
    setError(inputError);
    onChange(value, field, inputError);
  };

  $(function () {
    $('[data-toggle="tooltip"]').tooltip();
  });

  const handleTogglePassword = () => {
    setPass(!pass);
  };

  return (
    <div className={`input-field ${fieldError}`}>
      {label !== "" ? (
        <label className="inputField-label" htmlFor="inputField">
          {label}
          {isHelp && (
            <span
              style={{ display: "none" }}
              className="help"
              data-toggle="tooltip"
              data-placement="top"
              title={helpText}
            >
              <i className="ml-2 fa fa-info-circle" />
            </span>
          )}
        </label>
      ) : null}
      {type === "textarea" ? (
        <textarea
          autoComplete="off"
          {...extraProps}
          className="form-control"
          data-test={testId}
          aria-describedby="inputField"
          placeholder={placeholder}
          value={inputValue}
          onChange={handleChange}
          disabled={isDisabled}
        />
      ) : (
        <>
          <input
            autocomplete="off"
            {...extraProps}
            disabled={isDisabled}
            type={pass ? "text" : type}
            className="form-control"
            data-test={testId}
            aria-describedby="inputField"
            placeholder={placeholder}
            value={inputValue}
            onChange={handleChange}
            onKeyDown={
              type === "number" &&
              ((e) =>
                ["e", "E", "+", "-"].includes(e.key) && e.preventDefault())
            }
          />
          {isPassword && (
            <i
              onClick={handleTogglePassword}
              className={`cursor-pointer fa fa-${
                pass ? "eye-slash" : "eye"
              } passwordIcon`}
            />
          )}
        </>
      )}
      {error && error.error && (
        <span className="text-danger">{error.message}</span>
      )}
      {!error && hasError && fieldError && (
        <span className="text-danger">{strings.requiredText}</span>
      )}
    </div>
  );
};

InputField.propTypes = {
  type: PropTypes.string,
  isHelp: PropTypes.bool,
  isDisabled: PropTypes.bool,
  isPassword: PropTypes.bool,
  helpText: PropTypes.string,
  inputValue: PropTypes.any,
  onChange: PropTypes.func.isRequired,
  validators: PropTypes.array,
  label: PropTypes.string,
  testId: PropTypes.string,
  placeholder: PropTypes.string,
  field: PropTypes.string,
  extraProps: PropTypes.object,
};

InputField.defaultProps = {
  validators: [],
  label: "",
  testId: "",
  placeholder: "",
  field: "",
  type: "text",
  isDisabled: false,
  extraProps: {},
  helpText: "",
  isHelp: true,
  isPassword: false,
};

export default InputField;

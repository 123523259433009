import { storeConstants } from "../../library/common/constants";

const initialState = {
  AccountsList: [],
  userFilterCondition: {
    page: 1,
    filter: [],
    sort: [],
    graphql: null,
    size: 10,
    filterValue: {}
  }
};

const AccountsReducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case storeConstants.FETCH_ACCOUNTSLIST:
      return {
        ...state,
        AccountsList: action.payload
      };
    case storeConstants.PAGE_NUMBER_USER:
      return {
        ...state,
        userFilterCondition: action.payload
      };
    default:
      return state;
  }
};

export default AccountsReducer;

export const theme = {
  header: {
    backgroundColor: "#fff",
    borderColor: "#fff",
    textColor: "#333",
    headerLogoColor: "#333"
  },
  // primaryButton: {
  //     backgroundColor: '#28a745',
  //     borderColor: '#28a745',
  //     textColor: '#fff',
  // },
  sidemenu: {
    backgroundColor: "#263237",
    textColor: "rgba(255, 255, 255, 0.7)",
    activebackgroundColor: "#3a4248",
    activeTextColor: "#FC7334"
    // hoverBackgroundColor: '#63c2de',
    // hoverTextColor: '#fff',
  },
  content: {
    tableList: {
      headerbackgroundColor: "#F5F9FA",
      headertextColor: "#FC7334",
      trTextColor: "#333333"
    }
  }
};

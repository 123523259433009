import React, { Suspense, useEffect } from "react";
import { useDispatch } from "react-redux";

import { ErrorBoundary, Loader } from "./library/common/components";
import Routes from "./core/Routes";
import { getAuth } from "./library/common/actions";

const App = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getAuth());
  }, [dispatch]);

  return (
    <ErrorBoundary>
      <Suspense fallback={<Loader />}>
        <Routes />
      </Suspense>
    </ErrorBoundary>
  );
};

export default App;

export const BooleanFlag = [
  { id: true, name: "YES" },
  { id: false, name: "NO" },
];

export const Roles = [
  { id: 1, name: "USERS" },
  { id: 2, name: "ROLES_AND_PRIVILEGES" },
];

export const CusterSetviceStatus = [
  "VERIFIED",
  "UNVERIFIED",
  "PENDING_VERIFICATION",
];

import React, { memo, useState } from "react";
import * as $ from "jquery";
import axiosInstance from "../../../../core/Axios";
import {
  ActionMessage,
  Button,
  Loader
} from "../../../../library/common/components";
import { strings } from "../index";

const DeleteRow = props => {
  const [loading, setLoading] = useState(false);
  const [isError, setError] = useState(false);
  const [message, setMessage] = useState({
    display: false,
    type: "",
    message: ""
  });
  const handleSave = () => {
    setLoading(true);
    axiosInstance
      .delete(props.deleteUrl)
      .then(({ status }) => {
        if (status === 200) {
          setError(true);
          setLoading(false);
          setActionMessage(true, "Success", strings.common_delete_message);
          setTimeout(() => {
            closeModal();
            if (props?.totalSize === 1) {
              const sendObject = props?.initialRequest;
              props.updatedList({ ...sendObject, page: 1 });
              if (props.updatedFilter) {
                props.updatedFilter(true);
              }
            } else {
              props.isParams
                ? props.updatedList(props.url, props?.initialRequest)
                : props.updatedList(props?.initialRequest);
            }
          }, 500);
        }
      })
      .catch(err => {
        setError(true);
        setLoading(false);
        if (err.response) {
          setActionMessage(true, "Error", err.response.data.message);
        } else {
          setActionMessage(true, "Error", strings.something_went_wrong);
        }
      });
  };

  const setActionMessage = (display = false, type = "", message = "") => {
    setMessage({
      display,
      type,
      message
    });
  };

  const closeModal = () => {
    // e.preventDefault();
    setError(false);
    setActionMessage();
    setLoading(false);
    const value = $("div").is(".modal-backdrop.show");
    if (value) {
      $(".modal-backdrop").remove();
    }
    $(`#${props?.id || "delete-modal"}`).modal("hide");
  };

  return (
    <div
      data-backdrop="static"
      className="modal fade deleteModal"
      id={`${props?.id || "delete-modal"}`}
      tabIndex="-1"
      role="dialog"
      aria-labelledby="exampleModalCenterTitle"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-dialog-centered" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="exampleModalCenterTitle">
              {strings.common_delete_modal_header_text}
            </h5>
            {loading && <Loader />}
            <span
              className="close cursor-pointer"
              data-dismiss="modal"
              aria-label="Close"
              onClick={closeModal}
            >
              <span aria-hidden="true">&times;</span>
            </span>
          </div>
          <div className="modal-body">
            {message.display && (
              <ActionMessage
                message={message.message}
                type={message.type}
                styleClass={message.styleClass}
              />
            )}
            {!isError && (
              <p>{props?.bodyText || strings.common_delete_confirm_message}</p>
            )}
            <div className={`${isError && "text-center"}`}>
              {isError ? (
                <Button
                  value={strings.common_ok_title}
                  styleClass="btn-success mr-3"
                  onClick={closeModal}
                />
              ) : (
                <Button
                  value={strings.common_button_close}
                  icon="fa-times-circle"
                  styleClass="btn-danger mr-3"
                  onClick={closeModal}
                />
              )}
              {!isError && (
                <Button
                  styleClass="btn btn-primary mr-3"
                  value={strings.common_button_delete}
                  icon="fa-trash"
                  onClick={handleSave}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default memo(DeleteRow);
